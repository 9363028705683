// Node imports
import $ from 'jquery';
import _ from 'lodash';

import * as Animation from './WillisAnimation';
import * as Zone from './WillisZone';
import * as Util from './Util';
import * as Translations from './Translations';

interface DialogOptions {
    closeDialogOnBackdropClick?: boolean;
    closeDialogOnEsc?: boolean;
    style?: 'modal' | 'largeModal' | 'shade' | 'full' | 'sidebarLeft' | 'sidebarRight' | 'tool' | 'custom';
    actionLoc?: 'top' | 'bottom' | 'custom';
    actionText?: string;
    animationName?: string;
    animationSpeed?: Animation.speed;
}

interface CreateDialogOptions extends DialogOptions {
    customId?: string;
}

interface Dialog {
    id: string;
    title: string;
    originalContent: string;
    focusAfterClosed: HTMLElement;
    el: HTMLElement | null;
    backdrop: HTMLElement | null;
    zIndex: number;
    closeEvent: Function | null;
    closeEventSync: Function | null;
    options: any;
}

const defaults = {
    closeDialogOnBackdropClick: true,
    closeDialogOnEsc: true,
    style: 'modal',
    actionLoc: 'bottom',
    actionText: Translations.getItemFromView('Okay'),
    animationName: 'fade',
    animationSpeed: 'fast'
};

const openDialogList = [];
const idNumForBlankDialog = 100000;
let closeInProgress = false;

/**
 * Accessible dialog boxes. Note: any events added to elements will be removed
 * when the dialog is opened or closed. To get around this, always add your events
 * once the dialog has been opened with a callback function.
 *
 * @param id - The id of the element.
 * @param options - An object containing various options for how the dialog should be displayed, animate in, etc.
 * @see {@link https://advantagedesigngroup.sharepoint.com/:u:/r/sites/Designers/SitePages/Willis-Documentation.aspx?csf=1&web=1&share=EQvdnjpAbhhJmcGbYklOYaMBgEFe0DrRE-bS9jtR-O7LFA&e=cBRB0R Willis Documentation}
 */
export function open(id: string, options: DialogOptions, callback = null) {
    const originalEl = document.querySelector(`#${id}`);
    if (isActive(id) === true) {
        throw `Error: Dialog #${id} is already opened!`;
    }

    // Validate input
    if (!originalEl.hasAttribute('data-dialog-title')) {
        throw `Error: Dialog #${id} must have a "data-dialog-title" attribute!`;
    }

    if (originalEl.tagName !== 'WILLIS-DIALOG') {
        throw `Error: Dialog #${id} must have an tag name of 'willis-dialog' in order to be opened as a dialog`;
    }
    if (options.actionLoc === 'custom') {
        if (originalEl.querySelector('button.closeDialog') === null) {
            throw `Error: actionLoc param was set to 'custom' for dialog #${id}. It must contain a button with the class '.closeDialog'!`;
        }
    } else {
        if (originalEl.querySelector('.dialogActions') !== null) {
            throw `Error: Dialog #${id} must not contain an element with the class 'dialogActions' unless actionLoc is set to 'custom'!`;
        }

        if (originalEl.querySelector('.closeDialog') !== null) {
            throw `Error: Dialog #${id} must not contain a button with the class '.closeDialog' unless actionLoc is set to 'custom'!`;
        }
    }

    const dialog: Dialog = {
        id: id,
        title: document.querySelector(`#${id}`).getAttribute('data-dialog-title'),
        originalContent: originalEl.outerHTML,
        //focusAfterClosed: convertToDOMNode(focusAfterClosed),
        focusAfterClosed: <HTMLElement>document.activeElement,
        el: null,
        backdrop: null,
        zIndex: openDialogList.length + 500,
        closeEvent: null,
        closeEventSync: null,
        options: Object.assign({}, defaults, options)
    };

    const classes = originalEl.getAttribute('class') ? originalEl.getAttribute('class') : '';
    const $dialogContent = $(`#${id}`).children().clone(true);
    var containsText = false;
    $dialogContent.each(function () {
        if ($(this).is('p') === true) containsText = true;
    });
    const content = generateDialogContent(dialog, classes, containsText);

    $(`#${id}`).replaceWith(content);
    $dialogContent.appendTo(`#${id} .dialogContent`);

    dialog.el = document.querySelector(`#${id}`);
    dialog.backdrop = document.querySelector(`div[data-backdrop-for="${id}"]`);
    openDialogList.push(dialog);

    // The loading spinner dialog is kind of hacky, hence this check.
    if (id !== 'loading') {
        setHtmlOverflowAttr();
        setHtmlActiveDialogAttr();
    }
    setEventsAndAnimation(dialog, callback);
}

export function create(title, text, options?: CreateDialogOptions, callback = null) {
    var simpleId = `simpleDialog${idNumForBlankDialog}`;

    if (options && options.hasOwnProperty('customId')) {
        simpleId = options.customId;
    }
    const dialog: Dialog = {
        id: simpleId,
        title: title,
        originalContent: null,
        focusAfterClosed: <HTMLElement>document.activeElement,
        el: null,
        backdrop: null,
        zIndex: openDialogList.length + 500,
        closeEvent: null,
        closeEventSync: null,
        options: Object.assign({}, defaults, options)
    };

    const classes = 'simpleDialog';
    const content = generateDialogContent(dialog, classes, false);

    $('body').append(content);
    $(`#${dialog.id} .dialogContent`).append(text);
    dialog.el = document.querySelector(`#${dialog.id}`);
    dialog.backdrop = document.querySelector(`div[data-backdrop-for="${dialog.id}"]`);
    openDialogList.push(dialog);

    setHtmlActiveDialogAttr();
    setHtmlOverflowAttr();
    setEventsAndAnimation(dialog, callback);
}

/**
 * Close the active dialog.
 * @param callback
 */
export function closeActive(callback = null) {
    const active = getActiveDialog();
    close(active.id, callback, null);
}

/**
 * Closes all dialogs.
 * @param callback
 */
export function closeAll(callback = null) {
    // All the recursive functionality for actually closing multiple dialogs is contained
    // within the close() function, which is probably gross. but oh well.
    close(
        null,
        function () {
            if (typeof callback === 'function') {
                callback();
            }
        },
        null
    );
}

/**
 *
 * @param id - The id if the dialog. If this property isn't passed, all dialogs will be closed.
 * @param focusOverride
 * @param callback
 */
export function close(id = null, callback = null, focusOverride = null) {
    if (closeInProgress) {
        console.error('Close dialog procedure already in progress, no dialogs will be closed.');
        return false;
    }

    if (openDialogList.length > 0) {
        closeInProgress = true;

        const closeAll = id === null ? true : false;

        var dialog;

        if (id === null) {
            dialog = getActiveDialog();
        } else {
            dialog = getOpenDialogById(id);
        }
        dialog.el.setAttribute('data-dialog-animation-name', `${dialog.options.animationName}Out`);

        // Prevent weird vestigial focus states on buttons
        try {
            (document.activeElement as HTMLElement).blur();
        } catch (e) {
            console.log(e);
        }

        removeOpenDialogById(dialog.id);
        setHtmlActiveDialogAttr();

        if (dialog.closeEventSync !== null) {
            dialog.closeEventSync();
        }

        setTimeout(function () {
            dialog.backdrop.classList.add('out');
            setTimeout(function () {
                dialog.backdrop.classList.remove('out');

                const $changedContent = $(dialog.el).find('.dialogContent').children().clone();

                if (dialog.originalContent !== null) {
                    $(dialog.el).parent().replaceWith(dialog.originalContent);
                    $(`#${dialog.id}`).html('');
                    $changedContent.appendTo(`#${dialog.id}`);
                } else {
                    $(dialog.el).parent().remove();
                }

                if (!closeAll) {
                    if (focusOverride === null) {
                        dialog.focusAfterClosed.focus();
                    } else {
                        Util.convertToElement(focusOverride).focus();
                    }
                }

                setHtmlOverflowAttr();

                closeInProgress = false;
                if (dialog.closeEvent !== null) {
                    dialog.closeEvent();
                }

                if (openDialogList.length > 0 && closeAll) {
                    close(null, callback, null);
                } else {
                    if (openDialogList.length < 1) {
                        document.removeEventListener('focus', trapFocus, true);
                        document.removeEventListener('keydown', checkForEscapeKey, true);
                    }

                    if (typeof callback === 'function') {
                        callback();
                    }
                }
            }, Animation.getSpeed('veryFast'));
        }, Animation.getSpeed(dialog.options.animationSpeed));
    } else {
        if (typeof callback === 'function') {
            callback();
        }
    }
}

/**
 * Returns a callback whenever a given dialog is closed and its animation has been completed.
 * @param id - The ID of the dialog.
 * @param callback - Initiated whenever the user closes the dialog.
 *
 */
export function onClose(id, callback) {
    const i = getOpenDialogIndexById(id);
    if (openDialogList[i].closeEvent === null) {
        openDialogList[i].closeEvent = callback;
    } else {
        throw `Error: Dialog #${id} cannot have multiple close events!`;
    }
}

/**
 * Returns a callback whenever a given dialog is closed.
 * @param id - The ID of the dialog.
 * @param callback - Initiated whenever the user closes the dialog.
 *
 */
export function onCloseSync(id, callback) {
    const i = getOpenDialogIndexById(id);
    if (openDialogList[i].closeEventSync === null) {
        openDialogList[i].closeEventSync = callback;
    } else {
        throw `Error: Dialog #${id} cannot have multiple closeSync events!`;
    }
}

/**
 * @param - The id of the dialog.
 * @returns - True if the dialog is active.
 */
export function isActive(id) {
    return document.querySelector('html').getAttribute('data-active-dialog') === id;
}

function setHtmlActiveDialogAttr() {
    if (openDialogList.length > 0) {
        document.querySelector('html').setAttribute('data-active-dialog', getActiveDialog().id);
    } else {
        document.querySelector('html').setAttribute('data-active-dialog', '');
    }
}

function setHtmlOverflowAttr() {
    if (openDialogList.length > 0) {
        if ($('html').attr('data-active-dialog') !== 'simpleDialog100000') {
            document.querySelector('html').setAttribute('data-dialog-hide-overflow', 'true');
        }
    } else {
        document.querySelector('html').removeAttribute('data-dialog-hide-overflow');
    }
}

function checkForEscapeKey(evt) {
    if (evt.key === 'Escape' && getActiveDialog()) {
        // escape key maps to keycode `27`
        if (getActiveDialog().options.closeDialogOnEsc === true) {
            getActiveDialog().el.querySelector('button.closeDialog').click();
        }
    }
}

function trapFocus(evt) {
    const dialog = getActiveDialog();
    // We make an exception for trapping focus when bugherd is enabled.
    if (dialog && !dialog.el.contains(evt.target) && evt.target.nodeName !== 'BUGHERD-SIDEBAR') {
        const bouncerPos = document.activeElement.getAttribute('data-position');

        if (bouncerPos === 'start') {
            getLastFocusableDescendant(dialog.el).focus();
        } else {
            getFirstFocusableDescendant(dialog.el).focus();
        }
    }
}

function generateDialogContent(dialog: Dialog, classes, containsText) {
    var describedBy = '';

    if (containsText === true) {
        describedBy = `aria-describedby="${dialog.id}_desc"`;
    }

    const inner = `
        <div class="dialogInner">
            <div id="${dialog.id}_label" class="dialogLabel">
                <h2 tabindex="-1">${dialog.title}</h2>
            </div>
			${dialog.options.actionLoc === 'top' ? generateDialogActions(dialog.options.actionText) : ''}
            <div id="${dialog.id}_desc" class="dialogContent">
            </div>
            ${dialog.options.actionLoc === 'bottom' ? generateDialogActions(dialog.options.actionText) : ''}
        </div>
    `;

    return `
        <div class="dialogBackdrop" data-dialog-style="${dialog.options.style}" data-backdrop-for="${dialog.id}" style="z-index: ${dialog.zIndex};">
            <div class="bouncer" data-position="start" tabindex="0"></div>
            <div id="${dialog.id}" class="${classes}" role="dialog" ${describedBy} aria-labelledby="${dialog.id}_label" aria-modal="true" data-dialog-animation-speed="${dialog.options.animationSpeed}">
                ${inner}
            </div>
            <div class="bouncer" data-position="end" tabindex="0"></div>
        </div>
    `;
}

function generateDescribedBy(id) {
    return `aria-described-by="${id}"`;
}

function generateDialogActions(actionText) {
    return `
        <div class="dialogActions generated">
            <button class="closeDialog">
                <span aria-hidden="true" class="icon"></span>
                <span class="text">${actionText}</span>
            </button>
        </div>
    `;
}

/**
 * Recursively search a given element for its first focusable child element.
 * @param element
 */
function getFirstFocusableDescendant(element) {
    // The * means all.
    const descendents = element.getElementsByTagName('*');

    for (var i = 0; i < descendents.length; i++) {
        if (isFocusable(descendents[i]) === true) {
            if (descendents[i].closest('.kaltura-player-container')) {
                // Account for some evilness where elements inside a kaltura
                // player can't have their focus state set programmatically.
                return descendents[i].closest('.playkit-player');
            } else {
                return descendents[i];
            }
        }
    }
}

/**
 * Recursively search a given element for its last focusable child element.
 * @param element
 */
function getLastFocusableDescendant(element) {
    // The * means all.
    const descendents = element.getElementsByTagName('*');

    for (var i = descendents.length - 1; i >= 0; i--) {
        if (isFocusable(descendents[i]) === true) {
            if (descendents[i].closest('.kaltura-player-container')) {
                // Account for some evilness where elements inside a kaltura
                // player can't have their focus state set programmatically.
                return descendents[i].closest('.playkit-player');
            } else {
                return descendents[i];
            }
        }
    }
}

function setEventsAndAnimation(dialog: Dialog, callback) {
    dialog.backdrop.classList.add('in');
    dialog.el.style.display = 'none';
    setTimeout(function () {
        dialog.backdrop.classList.remove('in');

        dialog.el.style.display = '';
        dialog.el.setAttribute('data-dialog-animation-name', `${dialog.options.animationName}In`);
        // Prevent weird vestigial focus states on buttons
        try {
            (document.activeElement as HTMLElement).blur();
        } catch (e) {
            console.log(e);
        }

        setTimeout(function () {
            dialog.el.setAttribute('data-dialog-animation-name', '');

            dialog.el.querySelector<HTMLElement>('.dialogLabel h2').focus();

            document.removeEventListener('focus', trapFocus, true);
            document.addEventListener('focus', trapFocus, true);

            if (dialog.options.closeDialogOnEsc) {
                document.addEventListener('keydown', checkForEscapeKey, true);
            }

            // Close dialog when the close dialog action is clicked.
            dialog.el.querySelector('button.closeDialog').addEventListener('click', (evt) => {
                close(dialog.id, null, dialog.focusAfterClosed);
            });

            if (dialog.options.closeDialogOnBackdropClick === true) {
                dialog.backdrop.addEventListener('click', (evt) => {
                    if (dialog.backdrop !== evt.target) return;

                    dialog.el.querySelector<HTMLElement>('button.closeDialog').click();
                });
            }

            if (typeof callback === 'function') {
                callback();
            }
        }, Animation.getSpeed(dialog.options.animationSpeed));
    }, Animation.getSpeed('veryFast'));
}

/* Utilities* */

/**
 * Check if a given element can be focused.
 * @param element
 */
function isFocusable(element) {
    if (element.disabled || element.style.display === 'none' || element.style.visibility === 'hidden') {
        return false;
    }

    if (element.tabIndex > 0 || (element.tabIndex === 0 && element.getAttribute('tabIndex') !== null)) {
        return true;
    }

    switch (element.nodeName) {
        case 'A':
            return !!element.href && element.rel != 'ignore';
        case 'INPUT':
            return element.type != 'hidden' && element.type != 'file';
        case 'BUTTON':
        case 'SELECT':
        case 'TEXTAREA':
        case 'SUMMARY':
            return true;
        default:
            return false;
    }
}

function getActiveDialog() {
    return openDialogList[openDialogList.length - 1];
}

function getOpenDialogById(id) {
    for (var i = 0; i < openDialogList.length; i++) {
        if (openDialogList[i].id === id) {
            return openDialogList[i];
        }
    }
}

function removeOpenDialogById(id) {
    for (var i = 0; i < openDialogList.length; i++) {
        if (openDialogList[i].id === id) {
            openDialogList.splice(i, 1);
        }
    }
}

function getOpenDialogIndexById(id) {
    for (var i = 0; i < openDialogList.length; i++) {
        if (openDialogList[i].id === id) {
            return i;
        }
    }
}
