// Node imports
import $ from 'jquery';

/**
 * Checks if the window's viewport is a certain size.
 *
 * @param {string} size - The size of the viewport to match.
 * @returns - Returns true if window size is less than or equal to the breakpoint,
 * and returns false if not. If browser doesn't support matchMedia, then also
 * return false.
 */
export function viewport(size) {
    //An object of viewports based on our Sass breakpoints.
    var sizes = {
        xxs: '(max-width: 480px)',
        xs: '(max-width: 600px)',
        sm: '(max-width: 850px)',
        md: '(max-width: 1050px)',
        lg: '(max-width: 1280px)',
        xl: '(max-width: 1500px)'
    };
    var matches = false;

    if (sizes.hasOwnProperty(size)) {
        try {
            matches = window.matchMedia(sizes[size]).matches;
        } catch (e) {
            console.error('This browser does not support matchMedia. Returning false.');
        }
    } else {
        console.error('Media Query "', size, '" does not exist. Returning false.');
    }
    return matches;
}

/**
 *
 * @param identifier
 */
export function convertToElement(identifier) {
    if (typeof $ !== 'undefined' && identifier instanceof $) {
        return identifier.get([0]);
    } else if (identifier instanceof NodeList) {
        return identifier[0];
    } else if (typeof identifier === 'string') {
        return document.querySelector(identifier);
    } else if (identifier instanceof Element) {
        return identifier;
    }
}

/**
 *
 * @param identifier
 */
export function convertToListOfElements(identifier) {
    const els = [];

    if (typeof $ !== 'undefined' && identifier instanceof $) {
        identifier.each(function () {
            els.push($(this).get([0]));
        });
    } else if (identifier instanceof NodeList) {
        for (var i = 0; i < identifier.length; i++) {
            els.push(identifier[i]);
        }
    } else if (typeof identifier === 'string') {
        const query = document.querySelectorAll(identifier);

        for (var j = 0; j < query.length; j++) {
            els.push(query[j]);
        }
    } else if (identifier instanceof Element) {
        els.push(identifier);
    }

    return els;
}

export function debug() {
    document.onkeydown = checkKey;

    function checkKey(e) {
        e = e || window.event;

        if (e.keyCode == '38') {
            // up arrow
            //console.log(Sitemap.getAllSections());
        } else if (e.keyCode == '40') {
            // down arrow
        } else if (e.keyCode == '37') {
            // left arrow
        } else if (e.keyCode == '39') {
            // right arrow
        }
    }
}

/**
 *
 * @param key
 * @param url
 * @returns
 */
export function getQueryStringValue(key, url = window.location.href) {
    key = key.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + key + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

export function addOrUpdateQueryString(key, value, url = window.location.href) {
    // remove the hash part before operating on the url
    var i = url.indexOf('#');
    var hash = i === -1 ? '' : url.substr(i);
    url = i === -1 ? url : url.substr(0, i);

    var re = new RegExp('([?&])' + key + '=.*?(&|$)', 'i');

    let separator = '/?';
    if (url.indexOf('?') !== -1) {
        separator = '&';
    } else if (url.endsWith('/')) {
        separator = '?';
    }

    if (url.match(re)) {
        url = url.replace(re, '$1' + key + '=' + value + '$2');
    } else {
        url = url + separator + key + '=' + value;
    }
    return url + hash; // finally append the hash as well
}

export function groupBy(arr, property) {
    return arr.reduce(function (memo, x) {
        if (!memo[x[property]]) {
            memo[x[property]] = [];
        }
        memo[x[property]].push(x);
        return memo;
    }, {});
}

export function generateUniqueId(): string {
    // desired length of Id
    var idStrLen = 32;
    // always start with a letter -- base 36 makes for a nice shortcut
    var idStr = (Math.floor(Math.random() * 25) + 10).toString(36) + '_';
    // add a timestamp in milliseconds (base 36 again) as the base
    idStr += new Date().getTime().toString(36) + '_';
    // similar to above, complete the Id using random, alphanumeric characters
    do {
        idStr += Math.floor(Math.random() * 35).toString(36);
    } while (idStr.length < idStrLen);

    return idStr;
}

export function hasScroll(elToCheck, direction: 'vertical' | 'horizontal') {
    const el = convertToElement(elToCheck);
    const scrollDirection = direction === 'vertical' ? 'scrollTop' : 'scrollLeft';
    let result = !!el[scrollDirection];

    if (!result) {
        el[scrollDirection] = 1;
        result = !!el[scrollDirection];
        el[scrollDirection] = 0;
    }
    return result;
}

export function setScrollbarWidthCustomProperty() {
    const root = document.documentElement;
    const viewportWidth = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
    const bodyWidth = document.querySelector('body').clientWidth;

    const scrollbarWidth = viewportWidth - bodyWidth;
    root.style.setProperty('--scrollbar-width', String(scrollbarWidth) + 'px');

    return scrollbarWidth;
}
