import * as Util from './Util';
import * as SessionTimeout from './SessionTimeout';

/**
 * Unbreakable 🔳 click events.
 *
 * Stop! Is it possible for this element to be a button element? If so, make it that.
 * If not, this function will automagically add the proper keypress events,
 * a role="button" attribute, and a tabindex of 0 to said element.
 *
 *
 * @param identifier - The element to be clicked.
 * @param callback
 * @param delay {number} - The amount of time in milliseconds to wait after pressing to allow another press. Default is 1000.
 * @see {@link https://advantagedesigngroup.sharepoint.com/:u:/r/sites/Designers/SitePages/Willis-Documentation.aspx?csf=1&web=1&share=EQvdnjpAbhhJmcGbYklOYaMBgEFe0DrRE-bS9jtR-O7LFA&e=cBRB0R Willis Documentation}
 */
export function press(identifier, callback, delay: number = 1000) {
    const els = Util.convertToListOfElements(identifier);

    for (var i = 0; i < els.length; i++) {
        const el = els[i];

        let boundCallback;
        if (typeof callback === 'function') {
            boundCallback = callback.bind(el);
        }

        el.addEventListener('click', function (evt) {
            evt.preventDefault();
            evt.stopPropagation();

            if (!document.getElementById('membership')) {
                SessionTimeout.resetTimer();
            }

            if (isInactive(el) === false && isDisabled(el) === false) {
                makeInactive(el, delay);

                if (typeof callback === 'function') {
                    boundCallback(el);
                }
            }
        });

        if (true) {
            setAccessibleAttributes(el);

            el.addEventListener('keydown', function (evt) {
                if (isInactive(el) === false && isDisabled(el) === false) {
                    if (evt.keyCode === 32) {
                        // The action button is activated by space on the keyup event, but the
                        // default action for space is already triggered on keydown. It needs to be
                        // prevented to stop scrolling the page before activating the button.
                        evt.preventDefault();
                        evt.stopPropagation();

                        if (!document.getElementById('membership')) {
                            SessionTimeout.resetTimer();
                        }

                        makeInactive(el, delay);

                        if (typeof callback === 'function') {
                            boundCallback(el);
                        }
                    } else if (evt.keyCode === 13) {
                        // If enter is pressed, activate the button
                        evt.preventDefault();
                        makeInactive(el, delay);

                        if (!document.getElementById('membership')) {
                            SessionTimeout.resetTimer();
                        }

                        if (typeof callback === 'function') {
                            boundCallback(el);
                        }
                    }
                }
            });
            el.addEventListener('keyup', (evt) => {
                if (isInactive(el) === false && isDisabled(el) === false) {
                    if (evt.keyCode === 32) {
                        evt.preventDefault();
                        evt.stopPropagation();

                        if (!document.getElementById('membership')) {
                            SessionTimeout.resetTimer();
                        }

                        makeInactive(el, delay);

                        if (typeof callback === 'function') {
                            boundCallback(el);
                        }
                    }
                }
            });
        }
    }
}

function needsKeyBindings(el) {
    if (el.tagName === 'DIV' || el.tagName === 'A') {
        return true;
    } else {
        return false;
    }
}

function setAccessibleAttributes(el) {
    el.setAttribute('tabindex', '0');
    el.setAttribute('role', 'button');
}

function removeAccessibleAttributes(el) {
    el.removeAttribute('tabindex');
    el.removeAttribute('role');
}

function makeInactive(el, delay: number) {
    el.setAttribute('data-inactive', 'true');
    setTimeout(function () {
        el.setAttribute('data-inactive', 'false');
    }, delay);
}

function isInactive(el) {
    // So currently I'm using data-inactive as a kind of inbetween state between the prop
    // disabled and a class. It signifies, hey, this item can still be tabbed to but it's
    // waiting on an action to complete. If I set prop it adds a bunch of context-free "disabled"
    // messages read aloud, which I think is kind of confusing.
    // It may turn out that when this goes for testing the disabled prop is preferred and that's
    // easy enough to change back.
    if (el.getAttribute('data-inactive') === 'true') {
        return true;
    } else {
        return false;
    }
}

function isDisabled(el) {
    if (el.getAttribute('aria-disabled') === 'true') {
        return true;
    } else {
        return false;
    }
}
