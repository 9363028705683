// Node imports
import $ from 'jquery';
import Player from '@vimeo/player';

// ADG imports
import * as Util from './Util';
import * as Translations from './Translations';

declare var FEATURE_SETTINGS;
// YouTube API has to be loaded in a weird way on the Home partial,
// this var makes sure typescript knows that said API actually exists.
declare var YT;
// Same with the Kaltura API.
declare var KalturaPlayer;

type Host = 'youtube' | 'kaltura' | 'vimeo' | 'youku' | 'tencent qq' | 'bilibili';

type EventName = 'play' | 'ended';

export interface VideoOverrides {
    autoplay?: boolean;
    muted?: boolean;
    loop?: boolean;
}

interface VideoEvent {
    eventName: EventName;
    callback: Function;
}

/**
 * Returns an API wrapper around our video hosts.
 *
 * @param $videoHolder - An element with a .videoHolder class.
 */
export function load($videoHolder, overrides?: VideoOverrides): ApiWrapper {
    return new ApiWrapper($videoHolder, overrides);
}

export class ApiWrapper {
    player;

    readonly $videoHolder;
    protected _title: string;
    protected _id: string;
    protected _host: Host;
    protected _autoplay: boolean;
    protected _muted: boolean;
    protected _loop: boolean;
    protected _captionPref: boolean;
    protected _ready: boolean;
    protected _kalturaClient: boolean;
    protected _videoEvents: Array<VideoEvent>;

    constructor($videoHolder, overrides: VideoOverrides) {
        this.$videoHolder = $videoHolder;
        this._title = $videoHolder.attr('data-video-title');
        this._autoplay = this._setAutoplay(overrides);
        this._muted = this._setMuted(overrides);
        this._loop = this._setLoop(overrides);
        this._captionPref = $('body').attr('data-video-caption') === 'true';
        this._kalturaClient = $videoHolder.attr('data-kaltura-client-video') === 'true';
        this._videoEvents = [];

        checkForRegionalBlock($videoHolder.attr('data-player-type'), (blocked) => {
            const elToReplace = $videoHolder.find('.player').get([0]);
            if (blocked === true) {
                this._host = $videoHolder.attr('data-fallback-player-type');
                this._id = $videoHolder.attr('data-fallback-video-id');

                console.log(`${$videoHolder.attr('data-player-type')} player type is unavailable, falling back to ${this._host}`);
            } else {
                this._host = $videoHolder.attr('data-player-type');
                this._id = $videoHolder.attr('data-video-id');
            }

            if (this._id.indexOf('.mp4') !== -1 || this._id.indexOf('.webm') !== -1) {
                this._buildLocalVideo(elToReplace);
            } else if (this._host === 'kaltura') {
                this._buildKalturaVideo(elToReplace);
            } else if (this._host === 'youtube') {
                this._buildYouTubeVideo(elToReplace);
            } else if (this._host === 'vimeo') {
                this._buildVimeoVideo(elToReplace);
            } else if (this._host === 'youku') {
                this._buildYoukuVideo(elToReplace);
            } else if (this._host === 'tencent qq') {
                this._buildTencentVideo(elToReplace);
            } else if (this._host === 'bilibili') {
                this._buildBilibiliVideo(elToReplace);
            } else {
                elToReplace.innerHTML = `
                    <span class="hostError"><strong style="margin-right: 0.4em">${Translations.getItemFromView('Error')}: </strong> ${Translations.getItemFromView(
                        'Video not available in your region.'
                    )}</span>
                `;
            }
        });
    }

    play() {
        this._onReady().then(() => {
            if (this._id.indexOf('.mp4') !== -1 || this._id.indexOf('.webm') !== -1) {
                this.$videoHolder.find('video').get([0]).play();
            } else if (this._host === 'kaltura') {
                this.player.play();
            } else if (this._host === 'vimeo') {
                this.player.play();
            } else if (this._host === 'youtube') {
                this.player.playVideo();
            } else if (this._host === 'youku') {
                // Unable to play videos with youku api, fallback to recreating iframe.
                const elToReplace = this.$videoHolder.find('.player').get([0]);
                this._autoplay = this._setAutoplay({ autoplay: true });
                this._buildYoukuVideo(elToReplace);
            } else if (this._host === 'tencent qq') {
                // Unable to play videos with qq api, fallback to recreating iframe.
                const elToReplace = this.$videoHolder.find('.player').get([0]);
                this._autoplay = this._setAutoplay({ autoplay: true });
                this._buildTencentVideo(elToReplace);
            } else if (this._host === 'bilibili') {
                // Unable to play videos with bilibili api, fallback to recreating iframe.
                const elToReplace = this.$videoHolder.find('.player').get([0]);
                this._autoplay = this._setAutoplay({ autoplay: true });
                this._buildBilibiliVideo(elToReplace);
            }
        });
    }

    pause() {
        this._onReady().then(() => {
            if (this._id.indexOf('.mp4') !== -1 || this._id.indexOf('.webm') !== -1) {
                this.$videoHolder.find('video').get([0]).pause();
            } else if (this._host === 'kaltura') {
                this.player.pause();
            } else if (this._host === 'vimeo') {
                this.player.pause();
            } else if (this._host === 'youtube') {
                this.player.pauseVideo();
            } else if (this._host === 'youku') {
                // Unable to pause videos with youku api, fallback to straight up recreating iframe.
                const elToReplace = this.$videoHolder.find('.player').get([0]);
                this._autoplay = this._setAutoplay({ autoplay: false });
                this._buildYoukuVideo(elToReplace);
            } else if (this._host === 'tencent qq') {
                // Unable to pause videos with qq api, fallback to straight up recreating iframe.
                const elToReplace = this.$videoHolder.find('.player').get([0]);
                this._autoplay = this._setAutoplay({ autoplay: false });
                this._buildTencentVideo(elToReplace);
            } else if (this._host === 'bilibili') {
                // Unable to pause videos with bilibili api, fallback to straight up recreating iframe.
                const elToReplace = this.$videoHolder.find('.player').get([0]);
                this._autoplay = this._setAutoplay({ autoplay: false });
                this._buildBilibiliVideo(elToReplace);
            }
        });
    }

    destroy() {
        this._onReady().then(() => {
            if (this._id.indexOf('.mp4') !== -1 || this._id.indexOf('.webm') !== -1) {
                this.$videoHolder.find('.player').html('<div class="player"></div>');
            } else if (this._host === 'kaltura') {
                this.player.destroy();
            } else if (this._host === 'vimeo') {
                this.player.destroy();
            } else if (this._host === 'youtube') {
                this.player.destroy();
            }
            // TODO add destroy methods for chinese players. Their api support is spotty so
            // this may pose some difficulty.
        });
    }

    enableCaptions() {
        // When captions are enabled, try to select the language specified on the html lang attribute.
        // If captions for that language don't exist, we fall back to english.
        const lang: string = $('html').attr('lang');
        let trackLang = null;

        this._onReady().then(() => {
            if (this._host === 'kaltura') {
                // See https://developer.kaltura.com/player/web/managing-tracks-web
                const textTracks = this.player.getTracks(this.player.Track.TEXT);
                for (let i = 0; i < textTracks.length; i++) {
                    // Don't enable auto generated captions by default (users can still enable with caption button)
                    if (textTracks[i].label.includes('Auto Generated') === false) {
                        if (textTracks[i].language.includes(lang)) {
                            trackLang = textTracks[i];
                            break;
                        }
                        if (textTracks[i].language.includes('en')) {
                            trackLang = textTracks[i];
                        }
                    }
                }

                if (trackLang !== null) {
                    this.player.selectTrack(trackLang);
                } else if (textTracks.length > 0) {
                    // In the extremely unlikely event where there aren't any subtitles for
                    // the lang attribute OR english, just pick the first track in the list.
                    for (let i = 0; i < textTracks.length; i++) {
                        // Don't enable auto generated captions by default (users can still enable with caption button)
                        if (textTracks[i].label.includes('Auto Generated') === false) {
                            this.player.selectTrack(textTracks[i]);
                            break;
                        }
                    }
                }
            } else if (this._host === 'vimeo') {
                this.player
                    .getTextTracks()
                    .then((textTracks) => {
                        for (let i = 0; i < textTracks.length; i++) {
                            if (textTracks[i].language.indexOf(lang) !== -1) {
                                trackLang = textTracks[i].language;
                                break;
                            }
                            if (textTracks[i].language.indexOf('en') !== -1) {
                                trackLang = textTracks[i].language;
                            }
                        }

                        if (trackLang !== null) {
                            this.player
                                .enableTextTrack(trackLang)
                                .then((track) => {
                                    //console.log(track);
                                })
                                .catch((error) => {
                                    console.error(error);
                                });
                        } else if (textTracks.length > 0) {
                            // In the extremely unlikely event where there aren't any subtitles for
                            // the lang attribute OR english, just pick the first track in the list.
                            this.player
                                .enableTextTrack(textTracks[0].language)
                                .then((track) => {
                                    //console.log(track);
                                })
                                .catch((error) => {
                                    console.error(error);
                                });
                        }
                    })
                    .catch((error) => {
                        console.error(error);
                    });
            } else if (this._host === 'youtube') {
                let module;
                if (this.player.getOptions().indexOf('cc') !== -1) {
                    module = 'cc';
                } else if (this.player.getOptions().indexOf('captions') != -1) {
                    module = 'captions';
                }

                let textTracks = this.player.getOption(module, 'tracklist');
                for (let i = 0; i < textTracks.length; i++) {
                    if (textTracks[i].languageCode.includes(lang)) {
                        trackLang = textTracks[i].languageCode;
                        break;
                    }
                    if (textTracks[i].languageCode.includes('en')) {
                        trackLang = textTracks[i].languageCode;
                    }
                }

                if (trackLang !== null) {
                    this.player.setOption(module, 'track', { languageCode: trackLang });
                } else if (textTracks.length > 0) {
                    // In the extremely unlikely event where there aren't any subtitles for
                    // the lang attribute OR english, just pick the first track in the list.
                    this.player.setOption(module, 'track', { languageCode: textTracks[0].languageCode });
                }
            }
            // TODO add methods to hide captions for chinese players.
            // Their api support is spotty so this may pose some difficulty.
        });
    }

    disableCaptions() {
        this._onReady().then(() => {
            if (this._host === 'kaltura') {
                this.player.hideTextTrack();
            } else if (this._host === 'vimeo') {
                this.player.disableTextTrack();
            } else if (this._host === 'youtube') {
                this.player.unloadModule('captions');
            }
            // TODO add methods to hide captions for chinese players.
            // Their api support is spotty so this may pose some difficulty.
        });
    }

    // ------------------------- GETTERS -------------------------

    getDuration(): Promise<number> {
        return new Promise((resolve) => {
            this._onReady().then(() => {
                if (this._id.indexOf('.mp4') !== -1 || this._id.indexOf('.webm') !== -1) {
                    resolve(this.$videoHolder.find('.player').get([0]).duration);
                } else if (this._host === 'kaltura') {
                    resolve(this.player.duration);
                } else if (this._host === 'vimeo') {
                    this.player.getDuration().then((duration) => {
                        resolve(duration);
                    });
                } else if (this._host === 'youtube') {
                    resolve(this.player.getDuration());
                } else if (this._host === 'youku') {
                    // Unable to get via youku api, return a dirty lie.
                    resolve(5);
                } else if (this._host === 'tencent qq') {
                    // Unable to get via qq api, return a dirty lie.
                    resolve(5);
                } else if (this._host === 'bilibili') {
                    // Unable to get via bilibili api, return a dirty lie.
                    resolve(5);
                }
            });
        });
    }

    getHost(): Promise<Host> {
        return new Promise((resolve) => {
            this._onReady().then(() => {
                resolve(this._host);
            });
        });
    }

    getVideoId(): Promise<string> {
        return new Promise((resolve) => {
            this._onReady().then(() => {
                resolve(this._id);
            });
        });
    }

    getVideoTitle(): Promise<string> {
        return new Promise((resolve) => {
            this._onReady().then(() => {
                resolve(this._title);
            });
        });
    }

    // ------------------------- EVENTS -------------------------

    on(evtName: EventName, callback = null) {
        if (typeof callback === 'function') {
            this._videoEvents.push({
                eventName: evtName,
                callback: callback
            });
        }
    }

    protected _onPlay(e) {
        // TODO add play events for chinese players. Their api support is spotty so
        // this may pose some difficulty.
        this._videoEvents.forEach((evt) => {
            if (evt.eventName === 'play') {
                evt.callback(e);
            }
        });
    }

    protected _onEnded(e) {
        // TODO add play events for chinese players. Their api support is spotty so
        // this may pose some difficulty.
        this._videoEvents.forEach((evt) => {
            if (evt.eventName === 'ended') {
                evt.callback(e);
            }
        });
    }

    protected _onReady(): Promise<boolean> {
        return new Promise((resolve) => {
            if (this._ready === true) {
                resolve(true);
            } else {
                const checkIfReady = setInterval(() => {
                    if (this._ready === true) {
                        clearInterval(checkIfReady);
                        resolve(true);
                    }
                }, 150);
            }
        });
    }

    // ------------------------- BUILD -------------------------

    protected _buildLocalVideo(elToReplace) {
        const allowAutoplay = this._autoplay ? 'autoplay' : '';

        let video = `<video preload="metadata" class="player" ${allowAutoplay} src="${this._id}" controls playsinline></video>`;

        elToReplace.outerHTML = video;
        const player = this.$videoHolder.find('.player')[0];
        player.addEventListener('ended', function () {
            player.currentTime = 0;
        });
        this._ready = true;
    }

    protected _buildKalturaVideo(elToReplace) {
        checkIfKalturaApiReady(() => {
            // Clear out any locally stored preferences that Kaltura may have previously created,
            // as we want videos to follow whatever has been set in accessibility prefs.
            localStorage.removeItem('@playkit-js/kaltura-player-js_textLanguage');
            // Kaltura's API insists on targeting an id rather than a dom element.
            // So here we generate a unique ID and add it to the el.
            const uniqueId = Util.generateUniqueId();
            elToReplace.setAttribute('id', uniqueId);

            this.player = KalturaPlayer.setup({
                targetId: uniqueId,
                provider: {
                    //Checks if account id is from a client account or a ADG-setup account.
                    //Always takes ADG's uiConfid player settings to keep player setup consistent.
                    partnerId: this._kalturaClient === true ? $('body').attr('data-kaltura-client-partnerid') : FEATURE_SETTINGS.kalturaPartnerId,
                    uiConfId: FEATURE_SETTINGS.kalturaUiConfId
                },
                playback: {
                    disableUserCache: true,
                    autoplay: true,
                    allowMutedAutoPlay: true,
                    volume: this._muted === true ? 0 : 1,
                    loop: this._loop
                }
            });
            this.player.loadMedia({ entryId: this._id }).then(() => {
                // Kaltura doesn't load metadata until the video starts playing,
                // so we play be default and then pause the video immediately after.
                // We need the metadata for things like video duration.
                this.player.addEventListener(this.player.Event.Core.LOADED_METADATA, () => {
                    this.player.ready().then(() => {
                        this._ready = true;
                        if (this._captionPref === true) {
                            this.enableCaptions();
                        }
                        if (this._autoplay === false) {
                            // Hack, look into a better solution at some point. On safari-based browsers
                            // the this.player.pause() isn't getting triggered the first time.
                            // So we wait half a second and try again.
                            setTimeout(() => {
                                this.player.pause();
                            }, 500);
                            this.player.pause();
                            this.$videoHolder.find('.playkit-poster').css('display', 'block');
                        }
                        this.$videoHolder.find('.playkit-time-display').each(function () {
                            $(this).attr('aria-live', 'off');
                        });
                    });
                });
                // Show the video thumbnail when playback is complete.
                this.player.addEventListener(this.player.Event.Core.ENDED, (e) => {
                    this.$videoHolder.find('.playkit-poster').css('display', 'block');
                    this._onEnded(e);
                });
                this.player.addEventListener(this.player.Event.Core.PLAYING, (e) => {
                    this.$videoHolder.find('.playkit-poster').css('display', 'none');
                    this._onPlay(e);
                });
            });
        });
    }

    protected _buildVimeoVideo(elToReplace) {
        this.player = new Player(elToReplace, {
            id: this._id,
            autoplay: this._autoplay,
            muted: this._muted,
            loop: this._loop
        });
        if (this._captionPref === true) {
            this.enableCaptions();
        } else {
            this.player.disableTextTrack();
        }
        this.player.on('loaded', () => {
            this._ready = true;
            this.$videoHolder.find('iframe').attr('title', this._title);
        });
        this.player.on('ended', (e) => {
            this._onEnded(e);
        });
        this.player.on('play', (e) => {
            this._onPlay(e);
        });
    }

    protected _buildYouTubeVideo(elToReplace) {
        checkIfYouTubeApiReady(() => {
            this.player = new YT.Player(elToReplace, {
                videoId: this._id,
                playerVars: {
                    enablejsapi: 1,
                    cc_load_policy: this._captionPref === true ? 1 : 0,
                    cc_lang_pref: $('html').attr('lang'),
                    autoplay: this._autoplay === true ? 1 : 0,
                    playsinline: 1,
                    modestbranding: 1
                }
            });
            this.player.addEventListener('onReady', () => {
                if (this._muted === true) {
                    this.player.mute();
                }
                this.$videoHolder.find('iframe').attr('title', this._title);
                this._ready = true;
            });
            this.player.addEventListener('onStateChange', (e) => {
                if (e.data === YT.PlayerState.PLAYING) {
                    this._onPlay(e);
                }
                if (e.data === 1 && this._captionPref === true) {
                    this.enableCaptions();
                }
                if (e.data === YT.PlayerState.ENDED) {
                    this._onEnded(e);
                }
                if (e.data === YT.PlayerState.ENDED && this._loop === true) {
                    this.player.playVideo();
                }
            });
        });
    }

    protected _buildYoukuVideo(elToReplace) {
        const allowAutoplay = this._autoplay ? `allow="autoplay"` : '';
        const autoplay = this._autoplay ? `?autoplay=true` : '';

        let frame = `<iframe class="player" ${allowAutoplay} src='https://player.youku.com/embed/${this._id}${autoplay}' frameborder=0 'allowfullscreen'></iframe>`;

        elToReplace.outerHTML = frame;
        this.$videoHolder.find('iframe').attr('title', this._title);
        this._ready = true;
    }

    protected _buildTencentVideo(elToReplace) {
        const allowAutoplay = this._autoplay ? `allow="autoplay"` : '';
        const autoplay = this._autoplay ? `&autoplay=true` : '';

        let frame = `<iframe class="player" ${allowAutoplay} frameborder="0" src="https://v.qq.com/txp/iframe/player.html?vid=${this._id}${autoplay}" allowFullScreen="true"></iframe>`;

        elToReplace.outerHTML = frame;
        this.$videoHolder.find('iframe').attr('title', this._title);
        this._ready = true;
    }

    protected _buildBilibiliVideo(elToReplace) {
        const allowAutoplay = this._autoplay ? `allow="autoplay"` : ``;
        const autoplay = this._autoplay ? `&autoplay=1` : ``;

        // This variation of the iframe is the only one that doesn't implode when you enable autoplay.
        // let frame = `<iframe class="player" src="https://www.bilibili.com/blackboard/html5mobileplayer.html?aid=285427149&bvid=${this._id}&cid=182421834&page=1${autoplay}&danmaku=0&fjw=0" scrolling="no" border="0" frameborder="no" framespacing="0" ${allowAutoplay} allowfullscreen="true"> </iframe>`;
        let frame = `<iframe class="player" src="https://www.bilibili.com/blackboard/html5mobileplayer.html?bvid=${this._id}${autoplay}&danmaku=0&fjw=0" scrolling="no" border="0" frameborder="no" framespacing="0" ${allowAutoplay} allowfullscreen="true"> </iframe>`;

        elToReplace.outerHTML = frame;
        this.$videoHolder.find('iframe').attr('title', this._title);
        this._ready = true;
    }

    protected _setAutoplay(overrides: VideoOverrides) {
        if (typeof overrides !== 'undefined' && 'autoplay' in overrides && overrides.autoplay === false) {
            return false;
        } else if (typeof overrides !== 'undefined' && 'autoplay' in overrides && overrides.autoplay === true) {
            return true;
        } else if ($('body').attr('data-video-autoplay') === 'true') {
            return true;
        } else {
            return false;
        }
    }

    protected _setMuted(overrides: VideoOverrides) {
        if (typeof overrides !== 'undefined' && 'muted' in overrides && overrides.muted === false) {
            return false;
        } else if (typeof overrides !== 'undefined' && 'muted' in overrides && overrides.muted === true) {
            return true;
        } else {
            return false;
        }
    }

    protected _setLoop(overrides: VideoOverrides) {
        if (typeof overrides !== 'undefined' && 'loop' in overrides && overrides.loop === false) {
            return false;
        } else if (typeof overrides !== 'undefined' && 'loop' in overrides && overrides.loop === true) {
            return true;
        } else {
            return false;
        }
    }
}

export function checkIfYouTubeApiReady(callback) {
    if ($('body').attr('data-youtube-api-ready') === 'true') {
        callback();
    } else {
        let checker = setInterval(function () {
            if ($('body').attr('data-youtube-api-ready') === 'true') {
                clearInterval(checker);
                callback();
            }
        }, 500);
    }
}

export function checkIfKalturaApiReady(callback) {
    if (typeof KalturaPlayer !== 'undefined') {
        callback();
    } else {
        let checker = setInterval(function () {
            if (typeof KalturaPlayer !== 'undefined') {
                clearInterval(checker);
                callback();
            }
        }, 500);
    }
}

// I don't have a great way to encapsulate these variables so
// please don't reference them outside of checkForRegionalBlock.
type BlockedHosts = {
    [host in Host]: boolean | null;
};

let blocked: BlockedHosts = {
    kaltura: null,
    vimeo: null,
    youtube: null,
    'tencent qq': null,
    youku: null,
    bilibili: null
};

function checkForRegionalBlock(host: Host, callback) {
    //// for testing
    // callback(true);
    // return false;

    if (blocked[host] !== null) {
        callback(blocked[host]);
    } else {
        if (host === 'kaltura') {
            var url = 'https://corp.kaltura.com/';
        } else if (host === 'vimeo') {
            var url = 'https://vimeo.com/';
        } else if (host === 'youtube') {
            var url = 'https://www.youtube.com/';
        } else if (host === 'bilibili') {
            var url = 'https://www.bilibili.com/';
        } else if (host === 'youku') {
            var url = 'https://www.youku.com/';
        } else if (host === 'tencent qq') {
            var url = 'https://v.qq.com/';
        }

        fetch(url, { method: 'HEAD', mode: 'no-cors' })
            .then(() => {
                blocked[host] = false;
                callback(blocked[host]);
            })
            .catch((error) => {
                blocked[host] = true;
                callback(blocked[host]);
            });
    }
}
