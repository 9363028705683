// Globals
declare var FEATURE_SETTINGS;

// Node imports
import $ from 'jquery';

// ADG imports
import * as Dialog from './WillisDialog';

let timeoutInterval;
import * as Translations from './Translations';

export function logout() {
    sessionStorage.removeItem('RedirectTimer');
    sessionStorage.removeItem('WarningTimer');
    window.location.href = '/member-logout/';
}

export function executeSessionTimers() {
    isSessionValid()
        .then((sessionIsValid) => {
            if (sessionIsValid) {
                resetTimer(() => {
                    checkForTimeout();
                });
            } else {
                logout();
            }
        })
        .catch((error) => {
            console.error('Session validation failed.');
        });
}

export function resetTimer(callback = null) {
    setMemberLastActive();

    let sessionExpirationApi = new Request('/umbraco/api/session/RenewSession');
    fetch(sessionExpirationApi, { method: 'GET' })
        .then((response) => response.json())
        .then((response) => {
            if (response.status === 404) {
                // Umbraco has its own timeout that ends the member session regardless of any ADG code.
                // We've extended it artificially using the line services.ConfigureApplicationCookie in startup.cs.
                // It lasts about 120 minutes, so if a user stays logged in longer than that you may see this api
                // return a 404.
                // We then need to log the user out or things will start to break, slides won't load, calls won't
                // go through, etc.
                Dialog.create(
                    Translations.getItemFromView('Verify Your Login'),
                    Translations.getItemFromView('Session Maximum Length Message'),
                    {
                        actionText: Translations.getItemFromView('Log Out'),
                        style: 'shade',
                        closeDialogOnBackdropClick: false,
                        closeDialogOnEsc: false,
                        customId: 'sessionMaximum'
                    },
                    function () {
                        Dialog.onClose('sessionMaximum', function () {
                            logout();
                        });
                    }
                );
            } else {
                const sessionWarning = FEATURE_SETTINGS.sessionWarning;
                const redirectTimer = new Date(response);
                const warningTimer = new Date(redirectTimer.getTime() - sessionWarning * 60 * 1000);

                const redirectTimerObj = {
                    expiry: new Date(response).getTime()
                };

                const warningTimerObj = {
                    expiry: warningTimer.getTime()
                };

                // We use sessionStorage instead of cookies because of a bug where chrome doesn't
                // realize that cookies have expired.
                sessionStorage.setItem('RedirectTimer', JSON.stringify(redirectTimerObj));
                sessionStorage.setItem('WarningTimer', JSON.stringify(warningTimerObj));
            }

            if (typeof callback === 'function') {
                callback();
            }
        })
        .catch((err) => {
            // Firefox errs fetch requests when moving between pages, so you'll see this a lot in
            // simplified visuals. This is fine.
            // console.log(err);
        });
}

function checkForTimeout() {
    // This script will need to loop on intervals as long as the student is logged in.
    timeoutInterval = setInterval(() => {
        //  Check and see if WarningTimer cookie has died
        if (!isTimerExpired('RedirectTimer') && isTimerExpired('WarningTimer') && Dialog.isActive('sessionTimeoutWarning') === false && FEATURE_SETTINGS.mode !== 'alpha') {
            try {
                Dialog.open('sessionTimeoutWarning', { style: 'shade', actionLoc: 'custom', animationName: 'slideTop' }, function () {
                    $('#sessionTimeoutWarning .leaveBtn').click(function () {
                        logout();
                    });
                });
                Dialog.onClose('sessionTimeoutWarning', function () {
                    clearInterval(timeoutInterval);
                    resetTimer(() => {
                        checkForTimeout();
                    });
                });
            } catch (e) {
                // silence an annoying error that sometimes happens with opening the dialog
                console.log(e);
            }
        }

        if (isTimerExpired('RedirectTimer')) {
            logout();
        }
    }, 5000);
}

function isSessionValid(): Promise<boolean> {
    return fetch('/umbraco/api/session/IsSessionValid')
        .then((response) => {
            if (!response.ok) {
                throw new Error('Failed to validate the session.');
            }
            return response.json();
        })
        .then((data) => {
            return data as boolean;
        })
        .catch((error) => {
            return false;
        });
}

function setMemberLastActive(): void {
    fetch('/umbraco/api/session/SetMemberLastActive', { method: 'POST' });
}

function isTimerExpired(key) {
    const itemStr = sessionStorage.getItem(key);

    if (!itemStr) {
        return true;
    }

    const item = JSON.parse(itemStr);
    const now = new Date();
    // compare the expiry time of the item with the current time
    if (now.getTime() > item.expiry) {
        return true;
    }
    return item.value;
}
