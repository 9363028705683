// These also need to be changed in ~Base/_variables.scss if modified.
export type speed = 'veryFast' | 'fast' | 'medium' | 'slow' | 'verySlow' | 'glacial' | number;

const speedInMs = {
    veryFast: 200,
    fast: 300,
    medium: 500,
    slow: 800,
    verySlow: 1000,
    glacial: 3000
};

/**
 * Returns speed in milliseconds.
 *
 * @param speed - Either a Sass variable name or a number.
 * @returns The speed in milliseconds. Will be 10 if a preference for reduced animation is detected.
 * @see {@link https://advantagedesigngroup.sharepoint.com/:u:/r/sites/Designers/SitePages/Willis-Documentation.aspx?csf=1&web=1&share=EQvdnjpAbhhJmcGbYklOYaMBgEFe0DrRE-bS9jtR-O7LFA&e=cBRB0R Willis Documentation}
 */
export function getSpeed(speed: speed): number {
    if (prefersReducedAnimation() === true) {
        return 10;
    } else if (typeof speed === 'string') {
        return speedInMs[speed];
    } else {
        return speed;
    }
}

/**
 * Check user's animation preference.
 *
 * @returns true if user prefers reduced animation and false if they want ALL the movement.
 */
export function prefersReducedAnimation(): boolean {
    var browserPref: boolean;
    var userPref: boolean;

    userPref = document.querySelector('body').getAttribute('data-reduce-animation') === 'true';

    try {
        browserPref = window.matchMedia('(prefers-reduced-motion: reduce)').matches;
    } catch (err) {
        browserPref = false;
        console.log('Browser too old to determine animation preferences.');
    }

    if ((browserPref === true && userPref === true) || userPref === true) {
        return true;
    } else {
        return false;
    }
}
