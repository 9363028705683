// Node imports
import $ from 'jquery';

export function getItemFromView(item) {
    const $node = $(`#translationsForJs [data-dictionary-node="${item}"]`);
    // Checks to see if there is a translation item in the #translationsForJs <div> and then checks to make sure that <div> isn't empty
    if ($node.length > 0 && $node.text().trim().length > 0) {
        return $node.text().trim();
    } else {
        console.trace(`Error: There is no translation for ${item}`);
    }
}
