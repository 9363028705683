// Globals
declare var FEATURE_SETTINGS;

// Node imports
import $ from 'jquery';
import Bowser, { BROWSER_MAP } from 'bowser';

// ADG imports
import * as Checkbox from './WillisCheckbox';
import * as Dialog from './WillisDialog';
import * as Animation from './WillisAnimation';
import * as Zone from './WillisZone';
import * as Loading from './Loading';
import * as Sitemap from './Sitemap';
import * as Translations from './Translations';
import * as Background from '../Background';

interface ChangedPrefs {
    reduceAnimationChanged: boolean;
    autoplayChanged: boolean;
    captionChanged: boolean;
    simplifiedVisualsChanged: boolean;
    fontSizeChanged: boolean;
}

let memberPreferences = {
    SimplifiedVisualsEnabled: false,
    ReduceAnimationEnabled: false,
    AutoplayVideosEnabled: true,
    AlwaysShowVideoCaptionsEnabled: false,
    PreferredFontSize: 'small'
};

let prefWatcherCallback = null;

export function events() {
    let reduceAnimationChanged = false;
    let autoplayChanged = false;
    let captionChanged = false;
    let simplifiedVisualsChanged = false;
    let fontSizeChanged = false;

    Checkbox.press('#animationCheckbox', function (box) {
        const isChecked = $(box).prop('checked');
        reduceAnimationChanged = true;
        memberPreferences.ReduceAnimationEnabled = isChecked;
        localStorage.setItem('playBackground', (!isChecked).toString());
        $('body').attr('data-reduce-animation', isChecked);
    });

    Checkbox.press('#videoAutoplayCheckbox', function (box) {
        const isChecked = $(box).prop('checked');
        autoplayChanged = true;
        memberPreferences.AutoplayVideosEnabled = isChecked;
        $('body').attr('data-video-autoplay', isChecked);
    });

    Checkbox.press('#videoCaptionCheckbox', function (box) {
        const isChecked = $(box).prop('checked');
        captionChanged = true;
        memberPreferences.AlwaysShowVideoCaptionsEnabled = isChecked;
        $('body').attr('data-video-caption', isChecked);
        // Remove local storage to prevent kaltura from overriding accessibility pref.
        localStorage.removeItem('@playkit-js/kaltura-player-js_textLanguage');
    });

    Checkbox.press('#simplifiedVisualsCheckbox', function (box) {
        const isChecked = $(box).prop('checked');
        simplifiedVisualsChanged = true;
        memberPreferences.SimplifiedVisualsEnabled = isChecked;
        $('body').attr('data-simplified-visuals', isChecked);
    });

    $('input[name=fontSizes]').change(function () {
        const size = $('input[name=fontSizes]:checked').val().toString();
        fontSizeChanged = true;
        memberPreferences.PreferredFontSize = size;
        $('body').attr('data-font-size', size);
        $(`input[name=widgetFontSizes][value=${size}]`).prop('checked', true);
    });

    reportABarrierBtn();
    reportABarrier();

    Dialog.onClose('accessibilityPrefs', function () {
        if ((reduceAnimationChanged || captionChanged || autoplayChanged || simplifiedVisualsChanged || fontSizeChanged) && typeof prefWatcherCallback === 'function') {
            prefWatcherCallback({
                reduceAnimationChanged: reduceAnimationChanged,
                captionChanged: captionChanged,
                autoplayChanged: autoplayChanged,
                simplifiedVisualsChanged: simplifiedVisualsChanged,
                fontSizeChanged: fontSizeChanged
            });
        }

        $('#accessibilityPrefs').removeClass('reportNeeded');
        $('#accessibilityPrefs').find('#reportABarrierField').css('height', '100');
        $('#accessibilityPrefs').find('.reportABarrierForm').removeClass('formSubmitted');
        $('.reportABarrier').removeAttr('disabled');

        Loading.showSpinner();
        $.post('/umbraco/api/ClientApi/SaveMemberPreferences/', memberPreferences, function () {
            Loading.hideSpinner();
            if (memberPreferences.SimplifiedVisualsEnabled === true) {
                if (Sitemap.lastViewedSlideIsHomeNode() === true) {
                    window.location.reload();
                } else {
                    const lastViewedSlide = Sitemap.getSlide(Sitemap.getLastViewedSlideId());
                    if (lastViewedSlide) {
                        window.location.reload();
                    } else {
                        window.location.href = '/';
                    }
                }
            } else if (simplifiedVisualsChanged === true) {
                window.location.href = '/';
            }
        });

        if (Zone.isActive('home') && !Dialog.isActive('utilityMenu')) {
            Background.playBgSlideshow();
            Background.playBackgroundVideo();
        }
    });
}

function reportABarrierBtn() {
    $('#accessibilityPrefs')
        .find('.reportABarrier')
        .click(function () {
            $('.reportABarrier').attr('disabled', 'disabled');
            $('#accessibilityPrefs').addClass('reportNeeded');
        });
}

function reportABarrier() {
    $('.reportABarrierForm').submit(function (event) {
        event.preventDefault();

        var fieldValue = $('#reportABarrierField').val();
        reportBarrierSubmit(fieldValue);
    });
}

function reportBarrierSubmit(val) {
    Loading.showSpinner();
    $.ajax({
        url: '/umbraco/api/ClientApi/SubmitReportABarrier/?accessibilityIssue=' + val,
        cache: false,
        dataType: 'xml',
        type: 'GET',
        success: function (data, textStatus, jqXHR) {
            Loading.hideSpinner();
            Dialog.create(Translations.getItemFromView('Thank You'), Translations.getItemFromView('Your submission has been sent.'), { customId: 'barrierSubmitID' });
        },
        error: function (jqXHR, textStatus, errorThrown) {
            Loading.hideSpinner();
            console.log('Report a Barrier submission did not submit.');
        }
    });
}

export function syncPrefsWithBodyAttr() {
    const reduceAnimationBodyPref = $('body').attr('data-reduce-animation') === 'true';
    const videoAutoplayBodyPref = $('body').attr('data-video-autoplay') === 'true';
    const videoCaptionBodyPref = $('body').attr('data-video-caption') === 'true';
    const simplifiedVisualsBodyPref = $('body').attr('data-simplified-visuals') === 'true';
    const preferredFontSizeBodyPref = $('body').attr('data-font-size');

    memberPreferences.ReduceAnimationEnabled = reduceAnimationBodyPref;
    $('.animationCheckbox').prop('checked', reduceAnimationBodyPref);
    if (FEATURE_SETTINGS.mode === 'alpha') {
        $('.animationCheckbox').prop('disabled', true);
    }

    memberPreferences.AutoplayVideosEnabled = videoAutoplayBodyPref;
    $('.videoAutoplayCheckbox').prop('checked', videoAutoplayBodyPref);

    memberPreferences.AlwaysShowVideoCaptionsEnabled = videoCaptionBodyPref;
    $('.videoCaptionCheckbox').prop('checked', videoCaptionBodyPref);
    if (FEATURE_SETTINGS.mode === 'alpha' || FEATURE_SETTINGS.mode === 'beta') {
        $('.videoCaptionCheckbox').prop('disabled', true);
    }

    memberPreferences.SimplifiedVisualsEnabled = simplifiedVisualsBodyPref;
    $('.simplifiedVisualsCheckbox').prop('checked', simplifiedVisualsBodyPref);
    if (FEATURE_SETTINGS.mode === 'alpha' || FEATURE_SETTINGS.mode === 'beta') {
        $('.simplifiedVisualsCheckbox').prop('disabled', true);
    }

    memberPreferences.PreferredFontSize = preferredFontSizeBodyPref;
    if (preferredFontSizeBodyPref === 'small') {
        $('.smallFontRadio').prop('checked', true);
    } else if (preferredFontSizeBodyPref === 'medium') {
        $('.mediumFontRadio').prop('checked', true);
    } else if (preferredFontSizeBodyPref === 'large') {
        $('.largeFontRadio').prop('checked', true);
    }

    /**
     * The old procedure for setting animation preferences on page load is as follows:
     * 1. Check if a preference has already been set by the user. If not, check what the browser is telling us,
     *    and set the accessibility checkbox and necessary body classes based on that.
     * 2. If a value has already been set, ignore the browser and set the checkbox using that value.
     *
     * However, we're no longer reading the browser preference and relying solely on the user's stored preference.
     * Keeping this code commented out for posterity because the logic was slightly tricky and in case we need it again.
     */
    // if (animBodyPref !== 'false' && animBodyPref !== 'true') {
    //     if (window.matchMedia('(prefers-reduced-motion: reduce)').matches) {
    //         $('.animationCheckbox').prop('checked', true);
    //         $('body').attr('data-reduce-animation', 'true');
    //     } else {
    //         $('.animationCheckbox').prop('checked', false);
    //         $('body').attr('data-reduce-animation', 'false');
    //     }
    // } else {
    //     $('.animationCheckbox').prop('checked', animBodyPref === 'true');
    // }
}

/**
 * Only used in simplified visuals mode.
 */
export function fontSizeWidget() {
    if ($('.fontWidget').length > 0) {
        $('input[name=widgetFontSizes]').change(function () {
            const size = $('input[name=widgetFontSizes]:checked').val().toString();
            memberPreferences.PreferredFontSize = size;
            $('body').attr('data-font-size', size);
            $(`input[name=fontSizes][value=${size}]`).prop('checked', true);
            Loading.showSpinner();
            $.post('/umbraco/api/ClientApi/SaveMemberPreferences/', memberPreferences, function () {
                window.location.reload();
            });
        });
    }
}

/** Get browser info and append as a class to <html> tag. */
export function detectBrowser() {
    const fullName = Bowser.getParser(window.navigator.userAgent).getBrowserName();
    const version = Bowser.getParser(window.navigator.userAgent).getBrowserVersion();
    const engine = Bowser.getParser(window.navigator.userAgent).getEngineName();

    // BROWSER_MAP is imported from Bowser. Search for the shortened name and
    // use that instead of the full one, for the sake of convienence.
    for (const alias in BROWSER_MAP) {
        if (BROWSER_MAP.hasOwnProperty(alias)) {
            if (BROWSER_MAP[alias] === fullName) {
                $('html').addClass(alias);
                $('html').addClass(alias + parseInt(version));
                $('html').addClass(engine.toLowerCase());
                break;
            }
        }
    }

    // Add device types
    if (navigator.userAgent.match(/Android/i)) {
        $('html').addClass('android');
    }
    if (navigator.userAgent.match(/iPhone/i)) {
        $('html').addClass('iphone');
    }
    if (/iPad|Macintosh/.test(navigator.userAgent) && 'ontouchend' in document) {
        $('html').addClass('ipad');
    }

    // Check for touch
    if (window.matchMedia('(pointer: coarse)').matches) {
        $('html').addClass('touch');
    }
}

export function prefWatcher(callback: (changed: ChangedPrefs) => void) {
    prefWatcherCallback = callback;
}
export function clearPrefWatcher() {
    prefWatcherCallback = null;
}
