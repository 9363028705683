declare var FEATURE_SETTINGS;

// Node imports
import $ from 'jquery';
import _ from 'lodash';

import * as Dialog from './Shared/WillisDialog';
import * as Preferences from './Shared/Preferences';
import * as UpdateAccount from './Shared/UpdateAccount';
import * as Translations from './Shared/Translations';
import * as Animation from './Shared/WillisAnimation';
import * as Video from './Shared/Video';
import * as Util from './Shared/Util';
import * as Background from './Background';

function windowResizeEvents() {
    $(window).resize(function () {
        debouncedEvents();
    });

    if (window.matchMedia('(min-width: 850px)').matches) {
        $('#mainImg').removeClass('shrink');
    }

    $(window).on('orientationchange', function () {
        // Some funkiness can happen to the scrollbar width when changing orientation on mobile devices.
        // Best I can tell, the viewport width doesn't change instantly when you rotate your phone, instead
        // it animates it over the course of about half a second. Call the util function again to fix this.
        setTimeout(function () {
            Util.setScrollbarWidthCustomProperty();
        }, 1000);
    });

    const debouncedEvents = _.debounce(function () {
        // Place events here that you don't want happening until the user has finished resizing the window.
        // Good for performance intensive calculations or you only need the final window width/height, etc.
        Util.setScrollbarWidthCustomProperty();
        setTimeout(function () {
            Util.setScrollbarWidthCustomProperty();
        }, 1000);
    }, 150); // 150ms until events trigger
}

function inputFocus() {
    const $body = $('body');

    $(document)
        .on('focus', 'input', function () {
            $body.addClass('hideFooter');
        })
        .on('blur', 'input', function () {
            $body.removeClass('hideFooter');
        });
}

function formValidationDialog() {
    if ($('.field-validation-error').length > 0 || $('.profileErrorWrap .errorMessage').length > 0) {
        const openingLine = '<p>' + Translations.getItemFromView('Please address the following issues:') + '</p>';
        const closingLine = '<p>' + Translations.getItemFromView('When you are done, please resubmit this form.') + '</p>';
        var errorList = '<ul>';
        $('.statusMessage').each(function () {
            errorList = errorList + '<li>' + $(this).text() + '</li>';
        });
        $('.field-validation-error').each(function () {
            errorList = errorList + '<li>' + $(this).text() + '</li>';
        });
        errorList = errorList + '</ul>';
        Dialog.create(Translations.getItemFromView('Required information was not properly submitted.'), openingLine + errorList + closingLine, {
            customId: 'requiredInfoError'
        });
    }
}

function magicLoginDialog() {
    if ($('.statusMessage').length > 0) {
        Dialog.create($('.statusMessage').text().trim(), {});
    }
}

/**
 * Populate preferred name field with contents of first name, as long
 * as preferred name has not already been modified.
 *
 * This behavior should only happen on the create account page (not the update account dialog).
 */
function preferredName() {
    $('#FirstName').on('change paste keyup', function () {
        if (!$('#PreferredName').hasClass('populated')) {
            $('#PreferredName').val($(this).val());
        }
    });
    $('#PreferredName').on('change paste keyup', function () {
        $(this).addClass('populated');
    });
}

function twoFactorAuth() {
    const disabledDelay = 3; // In minutes

    $('#OTPButton').click(function () {
        if ($('#OTPButton').attr('data-wait-a-few') === 'true') {
            Dialog.create('Code Already Sent', 'Please wait a few minutes before requesting another.');
        } else {
            generateOTPEmail();
            $('#OTPButton').attr('data-wait-a-few', 'true');
            setTimeout(
                function () {
                    $('#OTPButton').attr('data-wait-a-few', 'false');
                },
                disabledDelay * 1000 * 60
            );
            Dialog.create('Code Sent', 'Check your email.');
        }
    });

    $('#TFAButtonApp').click(function () {
        getTFAInfo();
    });
    $('#TFAButtonEmail').click(function () {
        if ($('#TFAButtonEmail').attr('data-wait-a-few') === 'true') {
            Dialog.create('Code Already Sent', 'Please wait a few minutes before requesting another.');
        } else {
            getTFAInfo();
            $('#TFAButtonEmail').attr('data-wait-a-few', 'true');
            setTimeout(
                function () {
                    $('#TFAButtonEmail').attr('data-wait-a-few', 'false');
                },
                disabledDelay * 1000 * 60
            );
            Dialog.create('Code Sent', 'Check your email.');
        }
    });
}

function getTFAInfo() {
    const memberId = $('#TwoFactorAuth_AccountID').val();
    $.get('/umbraco/Surface/TwoFactorAuth/GetTFAInfo?memberId=' + memberId, function (response) {
        if (response.Data.TFATypes == '0') {
            $('input[name = "TwoFactorAuth.StringTimeGenerated"]').val(response.Data.StringTimeGenerated);
        } else if (response.Data.TFATypes == '1') {
            $('#TFAManualCode').text(response.Data.SetupInfo.ManualEntryKey);
            $('#TFAQRCODEIMAGE').attr('src', response.Data.SetupInfo.QrCodeSetupImageUrl);
            Dialog.create('QR Code', 'Code has been regenerated.');
        }
    });
}

/**
 * This only gets called the first time the user generates a one time code and TFAType is set to email in
 * feature settings.
 */
function generateOTPEmail() {
    const id = $('#TwoFactorAuth_AccountID').val();
    $.get('/umbraco/Surface/TwoFactorAuth/LoginGenerateOTPEmail?Id=' + id, function (response) {
        if (response.Data.TFATypes == '0') {
            $('input[name = "TwoFactorAuth.StringTimeGenerated"]').val(response.Data.StringTimeGenerated);
        }
    });
}

function toggleEmailSocial() {
    if ($('.loginPost').length > 0) {
        $('.guestLoginMethods').slideUp(function () {
            $('button.returnToSocial').show();
            $('.loginWrapper.guest').html($('.loginWrapper.standard').html());
            $('.guestWrapperHolder').css({ 'max-height': '450px', opacity: 1, overflow: 'visible' });
            UpdateAccount.showHidePassword();
        });
    }

    $('button.continueWithEmail').click(function () {
        $('.formWrap.login .statusMessage:first').slideUp();
        $('.guestLoginMethods').slideUp(function () {
            $('button.returnToSocial').show();
            $('.loginWrapper.guest').html($('.loginWrapper.standard').html());
            $('.guestWrapperHolder').css({ 'max-height': '450px', opacity: 1, overflow: 'visible' });
            UpdateAccount.showHidePassword();
        });
    });
    $('button.returnToSocial').click(function () {
        $('.guestWrapperHolder').css({ 'max-height': '', opacity: '', overflow: '' });
        $('button.returnToSocial').hide();
        setTimeout(function () {
            $('.loginWrapper.guest').html('');
            $('.guestLoginMethods').slideDown();
            $('.formWrap.login .statusMessage:first').slideDown();
        }, Animation.getSpeed(800));
    });
}

function loginTeaserVideo() {
    if (FEATURE_SETTINGS.layoutStyle.toLowerCase().includes('recruiting') && FEATURE_SETTINGS.teaserVideoOn && $('.loginTeaserVideo').length > 0) {
        $('.loginTeaserVideo').show();
        Video.load($('.loginTeaserVideo .videoHolder'), { autoplay: true, muted: true, loop: true });
    }
}

/**
 * The function is part of the Honeypot feature. It removes the required attribute from the invisible
 * field that only bots will see so that when a human fills out the form and doesn't fill out the
 * invisible field it won't be required.
 */
function confirmLegitSubmission() {
    setTimeout(function () {
        $('.enrolleeName input').removeAttr('required');
    }, 2000);
}

function draggableImage() {
    const startingOffset = $('#mainImg').offset();
    $('#mainImg').mousedown(function (e) {
        if (window.matchMedia('(min-width: 850px)').matches) {
            var $this = $(this);
            var isDragging = true;
            var offset = $this.offset();
            var mouseX = e.pageX - offset.left;
            var mouseY = e.pageY - offset.top;
            $('#mainImg').css('cursor', 'grab');
            $(document)
                .mousemove(function (e) {
                    if (isDragging) {
                        $this.css('transition', 'none');
                        $this.offset({
                            left: e.pageX - mouseX,
                            top: e.pageY - mouseY
                        });
                        $('#mainImg').css('cursor', 'grabbing');
                    }
                })
                .mouseup(function () {
                    isDragging = false;
                    $this.css('transition', 'left 500ms, top 500ms');
                    $this.offset(startingOffset);
                    $('#mainImg').css('cursor', '');
                });
        }
    });
}

function mainImgMobile() {
    $('#mainImg').click(function () {
        if (window.matchMedia('(max-width: 850px)').matches) {
            $('#mainImg').css('transition', '').css('left', '').css('top', '');
            $('#mainImg').toggleClass('shrink');
        }
    });
}

magicLoginDialog();
formValidationDialog();
inputFocus();
preferredName();
toggleEmailSocial();
Preferences.detectBrowser();
UpdateAccount.updateProfile();
UpdateAccount.showHidePassword();
UpdateAccount.passwordRequirements();
confirmLegitSubmission();
twoFactorAuth();
loginTeaserVideo();
draggableImage();
Background.backgroundControls();
mainImgMobile();
windowResizeEvents();
