// Globals
declare var FEATURE_SETTINGS;

// Node imports
import $ from 'jquery';

// ADG imports
import * as Dialog from './WillisDialog';
import * as Loading from './Loading';
import * as Translations from './Translations';
import * as Button from './WillisButton';

let showResetWarning: boolean = false;
let showPasswordConfirmation: boolean = false;

let fieldsNeedingConfirmation = [];
let initialEmailOrId = '';

export function events() {
    let allowSubmit = true;

    setUpdateRequiredAttributes();
    passwordConfirmation();
    passwordRequirements();
    $('#updateAccount').find('.dialogActions form').attr('action', '/');
    $('.updateAccount form').on('submit', function (e) {
        e.preventDefault();
        if (showPasswordConfirmation) {
            Dialog.open('updateAccountPasswordConfirmation', { style: 'modal', actionLoc: 'custom' }, function () {
                showHidePassword();
                if ($('#DialogPasswordConfirmation').val() == '') {
                    $('.submitAndClose').attr('disabled', true);
                }
                $('#DialogPasswordConfirmation').on('keyup', function () {
                    if (this.value !== '') {
                        $('.submitAndClose').removeAttr('disabled');
                    } else {
                        $('.submitAndClose').attr('disabled', true);
                    }
                });
                Button.press('#updateAccountPasswordConfirmation button.submitAndClose', function () {
                    $('#PasswordConfirmation').val($('#DialogPasswordConfirmation').val());
                    showPasswordConfirmation = false;
                    $('.updateAccount form').submit();
                });
            });
        } else {
            // Prevent double clicking of submit button from causing an error.
            if (allowSubmit) {
                allowSubmit = false;
            } else {
                return false;
            }
            Loading.showSpinner();
            let dataToSend;
            switch ($('#updateAccount .dialogActions').attr('data-memberType').toLowerCase()) {
                case 'enrollee':
                    dataToSend = $('#updateEnrolleeAccountForm :input').serializeArray();
                    $.get('/umbraco/Surface/MemberUpdateAccountSurface/UpdateEnrolleeAccount', dataToSend, function (response) {
                        var passwordConfirmationObject = dataToSend.find((field) => {
                            return field.name === 'PasswordConfirmation';
                        });
                        var passwordConfirmationValue = passwordConfirmationObject.value.trim();
                        var errorsExist = response.search('data-has-error="True"');
                        if (passwordConfirmationValue.length > 0 && errorsExist == -1) {
                            window.location.reload();
                        } else {
                            Loading.hideSpinner();
                            const $resp = $($.parseHTML(`<div>${response}</div>`));
                            const $refreshedForm = $resp.find('#updateEnrolleeAccountForm').clone();
                            const $updatedFieldsList = $resp.find('#updatedFields').clone();
                            $('#accountInfoUpdated .description').html('').append($updatedFieldsList);
                            Dialog.open('accountInfoUpdated', { style: 'modal', actionLoc: 'custom' }, function () {
                                $('#updateAccount').find('#updateEnrolleeAccountForm').replaceWith($refreshedForm);
                                $('#updateAccount').find('.dialogActions form').attr('action', '/');
                                events();
                            });
                            $('#accountInfoUpdated').find('.text').show();
                            if ($updatedFieldsList.text().indexOf('error') === -1) {
                                $('#accountInfoUpdated').find('.hasError').hide();
                                Dialog.onClose('accountInfoUpdated', function () {
                                    Dialog.close('updateAccount');
                                });
                            } else {
                                $('#accountInfoUpdated').find('.text:not(.hasError)').hide();
                                Dialog.onClose('accountInfoUpdated', function () {
                                    Dialog.close('updateAccountPasswordConfirmation');
                                    $('#DialogPasswordConfirmation').val('');
                                });
                            }
                        }
                    });
                    break;
                case 'guest':
                    dataToSend = $('#updateGuestAccountForm :input').serializeArray();
                    $.get('/umbraco/Surface/MemberUpdateAccountSurface/UpdateGuestAccount', dataToSend, function (response) {
                        var passwordConfirmationObject = dataToSend.find((field) => {
                            return field.name === 'PasswordConfirmation';
                        });
                        var passwordConfirmationValue = passwordConfirmationObject.value.trim();
                        var errorsExist = response.search('data-has-error="True"');
                        if (passwordConfirmationValue.length > 0 && errorsExist == -1) {
                            window.location.reload();
                        } else {
                            Loading.hideSpinner();
                            const $resp = $($.parseHTML(`<div>${response}</div>`));
                            const $refreshedForm = $resp.find('#updateGuestAccountForm').clone();
                            const $updatedFieldsList = $resp.find('#updatedFields').clone();
                            $('#accountInfoUpdated .description').html('').append($updatedFieldsList);
                            Dialog.open('accountInfoUpdated', { style: 'modal', actionLoc: 'custom' }, function () {
                                $('#updateAccount').find('#updateGuestAccountForm').replaceWith($refreshedForm);
                                $('#updateAccount').find('.dialogActions form').attr('action', '/');
                                events();
                            });
                            $('#accountInfoUpdated').find('.text').show();
                            if ($updatedFieldsList.text().indexOf('error') === -1) {
                                $('#accountInfoUpdated').find('.hasError').hide();
                                Dialog.onClose('accountInfoUpdated', function () {
                                    Dialog.close('updateAccount');
                                });
                            } else {
                                $('#accountInfoUpdated').find('.text:not(.hasError)').hide();
                                Dialog.onClose('accountInfoUpdated', function () {
                                    Dialog.close('updateAccountPasswordConfirmation');
                                    $('#DialogPasswordConfirmation').val('');
                                });
                            }
                        }
                    });
                    break;
                case 'staff':
                    dataToSend = $('#updateStaffAccountForm :input').serializeArray();
                    $.get('/umbraco/Surface/MemberUpdateAccountSurface/UpdateStaffAccount', dataToSend, function (response) {
                        var passwordConfirmationObject = dataToSend.find((field) => {
                            return field.name === 'PasswordConfirmation';
                        });
                        var passwordConfirmationValue = passwordConfirmationObject.value.trim();
                        var errorsExist = response.search('data-has-error="True"');
                        if (passwordConfirmationValue.length > 0 && errorsExist == -1) {
                            window.location.reload();
                        } else {
                            Loading.hideSpinner();
                            const $resp = $($.parseHTML(`<div>${response}</div>`));
                            const $refreshedForm = $resp.find('#updateStaffAccountForm').clone();
                            const $updatedFieldsList = $resp.find('#updatedFields').clone();
                            $('#accountInfoUpdated .description').html('').append($updatedFieldsList);
                            Dialog.open('accountInfoUpdated', { style: 'modal', actionLoc: 'custom' }, function () {
                                $('#updateAccount').find('#updateStaffAccountForm').replaceWith($refreshedForm);
                                $('#updateAccount').find('.dialogActions form').attr('action', '/');
                                events();
                            });
                            $('#accountInfoUpdated').find('.text').show();
                            if ($updatedFieldsList.text().indexOf('error') === -1) {
                                $('#accountInfoUpdated').find('.hasError').hide();
                                Dialog.onClose('accountInfoUpdated', function () {
                                    Dialog.close('updateAccount');
                                });
                            } else {
                                $('#accountInfoUpdated').find('.text:not(.hasError)').hide();
                                Dialog.onClose('accountInfoUpdated', function () {
                                    Dialog.close('updateAccountPasswordConfirmation');
                                    $('#DialogPasswordConfirmation').val('');
                                });
                            }
                        }
                    });
                    break;
            }
        }
    });

    showHidePassword();
}

export function updateProfile() {
    if ($('.updateProfiler').length > 0) {
        $('input:not([name="SupportedLanguages"])').on('change', function () {
            showResetWarning = true;
        });
        $('.updateProfiler form').on('submit', function (evt) {
            evt.preventDefault();
            const $formToSubmit = $(this);
            if (showResetWarning) {
                Dialog.open('resetData', { style: 'modal', closeDialogOnBackdropClick: false, closeDialogOnEsc: false, actionLoc: 'custom' }, function () {
                    $('button.updateData').click(function () {
                        $formToSubmit.unbind('submit').submit();
                    });
                });
            } else {
                $formToSubmit.unbind('submit').submit();
            }
        });
    }
}

export function showHidePassword() {
    $('.showHide')
        .off()
        .click(function () {
            var $this = $(this).closest('.fieldWrapper');
            let pressed = $this.find('.showHide').attr('aria-checked');
            $this.find('.showHide').attr('aria-checked', pressed === 'true' ? 'false' : 'true');
            $this.find('input').attr('type', pressed === 'true' ? 'password' : 'text');
        });
}

function passwordConfirmation() {
    fieldsNeedingConfirmation = [];
    $('.updateAccount').attr('aria-live', 'polite');
    $('.updateAccount form')
        .find('[data-password-confirmation="true"]')
        .each(function () {
            fieldsNeedingConfirmation.push({
                originalValue: $(this).val(),
                $el: $(this)
            });
            if (initialEmailOrId === '') {
                initialEmailOrId = fieldsNeedingConfirmation[0].$el.val();
            }
            if (initialEmailOrId !== fieldsNeedingConfirmation[0].originalValue) {
                showPasswordConfirmation = true;
            }
            fieldsNeedingConfirmation.every((field) => {
                $(this).on('keyup', function () {
                    if (initialEmailOrId !== field.$el.val() || $('#Password').val().length > 0 || $('#RePassword').val().length > 0) {
                        showPasswordConfirmation = true;
                        return false;
                    } else {
                        showPasswordConfirmation = false;
                        return true;
                    }
                });
            });
        });
}

function setUpdateRequiredAttributes() {
    $('#updateAccount .enrolleeName input').removeAttr('required');
    $('#updateAccount input[type=password]').removeAttr('required');
}

export function passwordRequirements() {
    $('#Password').on('change input', function (e) {
        const value = $(this).val(); // Password input current value
        const requirements = {
            length: value.length >= 12, // At least 12 characters long
            number: /[0-9]/.test(value), // Contains a number
            lowercase: /[a-z]/.test(value), // Contains a lowercase letter
            uppercase: /[A-Z]/.test(value), // Contains a uppercase letter
            special: /[^a-zA-Z0-9]/g.test(value) // Contains a special character
        };
        $.each(requirements, function (type, validation) {
            const status = validation ? 'valid' : 'invalid';
            $('#Password').closest('.fieldWrapper').find(`#requirements li[data-validation-type="${type}"]`).attr('class', status);
        });
    });
}
