import $ from 'jquery';

import * as Button from './Shared/WillisButton';
import * as Translations from './Shared/Translations';

let homeSlideshowTimeout = null;
export function playBgSlideshow() {
    const $slideshow = $('#background .cover-slider');
    const slideshowType = $slideshow.attr('data-slideshow');
    if ($('#background .cover-slider').length && localStorage.getItem('playBackground') !== 'false') {
        $slideshow.removeClass('paused');
        $slideshow.removeClass('returnPause');
        if (slideshowType != 'zoomSlideshow') {
            const $slides = $slideshow.find('.bgImg');
            if ($slides.filter('.current').length < 1) {
                $slides.first().addClass('current');
                slideshowAnimation(0);
            } else {
                const currentSlideIndex = $slides.filter('.current').index();
                slideshowAnimation(currentSlideIndex);
            }
            $slides.not('.current').hide();
        } else {
            const $slides = $slideshow.find('.bgImgWrapper');
            $slides.filter(':first-child').addClass('first');
            $slides.filter(':last-child').addClass('last');
            if ($slides.filter('.current').length < 1) {
                $slides.first().addClass('current').find('.bgImg').addClass('trans').closest('.bgImgWrapper').next().addClass('next');
            }
            zoomSlideshowAnimation($slideshow);
        }
    } else {
        $slideshow.addClass('returnPause');
    }
    updateBackgroundButton();
}

function zoomSlideshowAnimation(slideshow) {
    var $slide = slideshow.find('.bgImgWrapper');
    const slideInterval = 5000;
    homeSlideshowTimeout = setTimeout(function () {
        $slide.filter('.current').find('.bgImg').addClass('trans');
        setTimeout(function () {
            $slide.filter('.next').find('.bgImg').addClass('trans');
            $slide.filter('.current').addClass('prev').removeClass('current');
            $slide.filter('.next').removeClass('next').addClass('current');
            setTimeout(function () {
                $slide.filter('.prev').removeClass('prev').find('.bgImg').removeClass('trans');
            }, 850);
            if ($slide.filter('.current').hasClass('last')) {
                $slide.filter(':first-child').addClass('next');
            } else {
                $slide.filter('.current').next().addClass('next');
            }
        }, 750);
        // Check home slideshow timeout again in case slide show was paused during transition.
        if (homeSlideshowTimeout) {
            zoomSlideshowAnimation(slideshow);
        }
    }, slideInterval);
}

function slideshowAnimation(currentSlideIndex) {
    const $slider = $('#background .cover-slider');
    const $slides = $slider.find('.bgImg');
    const slideInterval = 4200;

    const animIn = [{ opacity: '0' }, { opacity: '1' }];
    const animOut = [{ opacity: '1' }, { opacity: '0' }];
    const animTiming = {
        duration: 1000,
        iterations: 1
    };
    homeSlideshowTimeout = setTimeout(function () {
        currentSlideIndex++;
        if (currentSlideIndex >= $slides.length) {
            currentSlideIndex = 0;
        }
        const prev = $slides.filter('.current').get([0]);
        prev.classList.remove('current');

        const prevAnim = prev.animate(animOut, animTiming);
        prevAnim.onfinish = (e) => {
            prevAnim.cancel();
            prev.style.display = 'none';
        };

        const next = $slides.eq(currentSlideIndex).get([0]);
        next.classList.add('current');
        next.style.display = 'block';

        const nextAnim = next.animate(animIn, animTiming);
        nextAnim.onfinish = (e) => {
            nextAnim.cancel();

            // Check home slideshow timeout again in case slide show was paused during transition.
            if (homeSlideshowTimeout) {
                slideshowAnimation(currentSlideIndex);
            }
        };
    }, slideInterval);
}

export function pauseBgSlideshow() {
    if ($('#background .cover-slider').length) {
        clearTimeout(homeSlideshowTimeout);
        homeSlideshowTimeout = null;
        const slideshowType = $('.cover-slider').attr('data-slideshow');
        if (slideshowType === 'zoomSlideshow') {
            $('.cover-slider').addClass('paused');
            if ($('#background .cover-slider .bgImgWrapper').filter('.current').length < 1) {
                $('.cover-slider').addClass('returnPause');
            }
        }
    }
    updateBackgroundButton();
}

export function backgroundControls() {
    const playBackground = localStorage.getItem('playBackground');
    if (playBackground === null) {
        localStorage.setItem('playBackground', 'true');
    }

    if ($('body').hasClass('membershipPage') && playBackground === 'false') {
        pauseBackgroundVideo();
        $('#background video').removeAttr('autoplay');
    }

    updateBackgroundButton();
    const $slideshow = $('#background .cover-slider');
    Button.press('#backgroundPlayBtn,#backgroundPlayBtnMobile', function () {
        $(this).toggleClass('pause');
        $(this).attr('aria-label', Translations.getItemFromView($(this).hasClass('pause') ? 'Play Background' : 'Pause Background'));
        if ($(this).hasClass('pause')) {
            localStorage.setItem('playBackground', 'false');
            pauseBgSlideshow();
            pauseBackgroundVideo();
        } else {
            localStorage.setItem('playBackground', 'true');
            playBgSlideshow();
            playBackgroundVideo();
        }
    });
}

export function updateBackgroundButton() {
    const playBackground = localStorage.getItem('playBackground');
    if (playBackground === 'false') {
        $('#backgroundPlayBtn,#backgroundPlayBtnMobile').addClass('pause');
        $('#backgroundPlayBtn,#backgroundPlayBtnMobile').attr('aria-label', Translations.getItemFromView('Play Background'));
    } else {
        $('#backgroundPlayBtn,#backgroundPlayBtnMobile').removeClass('pause');
        $('#backgroundPlayBtn,#backgroundPlayBtnMobile').attr('aria-label', Translations.getItemFromView('Pause Background'));
    }
}

export function pauseBackgroundVideo() {
    const bgVids = document.querySelectorAll<HTMLVideoElement>('#background video');
    bgVids.forEach((vid) => {
        const isPlaying = vid.currentTime > 0 && !vid.paused && !vid.ended && vid.readyState > 2;
        if (isPlaying === true) {
            vid.pause();
        }
    });
    updateBackgroundButton();
}

export function playBackgroundVideo() {
    const bgVids = document.querySelectorAll<HTMLVideoElement>('#background video');
    bgVids.forEach((vid) => {
        const isPlaying = vid.currentTime > 0 && !vid.paused && !vid.ended && vid.readyState > 2;
        if (localStorage.getItem('playBackground') !== 'false' && isPlaying === false) {
            vid.play();
        }
    });
    updateBackgroundButton();
}
