// Node imports
import $ from 'jquery';
import _ from 'lodash';

let sitemap;
let homeNodeId;
let loaded = false;
let inSelfDirected = false;
let currentSelfDirectedTopic = null;

export function refreshSiteMap(callback = null, storeLocal = false) {
    loaded = false;

    const previewMode = $('body').attr('data-is-previewMode') === 'true';

    $.get('/umbraco/api/clientapi/getsitemap', {isInPreview: previewMode} ).done(function (data) {
        //console.log(data);
        loaded = true;
        sitemap = data;
        homeNodeId = sitemap.ID;

        for (let i = 0; i < sitemap.Sections.length; i++) {
            sitemap.Sections[i].IsCurrent = false;
            sitemap.Sections[i].Slides = sitemap.Sections[i].Slides.map((s) => {
                let slide = Object.assign({}, s);
                slide.Content = null;
                slide.IsCurrent = false;
                slide.IsLoading = false;
                slide.IsDirty = false;

                if (slide.SlideType === 'slideSelfDirected') {
                    for (let k = 0; k < slide.SelfDirectedTopics.length; k++) {
                        let topic = slide.SelfDirectedTopics[k];
                        for (let l = 0; l < topic.Slides.length; l++) {
                            let nestedSlide = topic.Slides[l];
                            nestedSlide.Content = null;
                            nestedSlide.IsCurrent = false;
                            nestedSlide.IsLoading = false;
                            nestedSlide.IsDirty = false;
                        }
                    }
                }

                return slide;
            });
        }

        // Store sitemap for Simplified Visuals mode.
        if (storeLocal === true) {
            localStorage.setItem('sitemap', JSON.stringify(sitemap));
            const sitemapExpir = new Date(new Date().getTime() + 5 * 60 * 1000);
            document.cookie = `SitemapFresh=true; expires=${sitemapExpir.toUTCString()}; path=/`;
        } else {
            localStorage.removeItem('sitemap');
            document.cookie = 'SitemapFresh=true; Max-Age=-99999999; path=/';
        }

        if (typeof callback === 'function') {
            callback();
        }
    });
}

/**
 * Only used in the Simplified Visuals mode. Makes an API call if the sitemap is stale.
 * This is used to prevent the call from going through every single time you load a new slide.
 */
export function loadSitemapFromLocalStorage(callback) {
    if (localStorage.getItem('sitemap') === null || document.cookie.indexOf('SitemapFresh=') === -1) {
        refreshSiteMap(function () {
            callback();
        }, true);
    } else {
        sitemap = JSON.parse(localStorage.getItem('sitemap'));
        console.log(sitemap);
        homeNodeId = sitemap.ID;
        callback();
    }
}

function saveSitemapToLocalStorage() {
    if (localStorage.getItem('sitemap') !== null) {
        localStorage.setItem('sitemap', JSON.stringify(sitemap));
    }
}

/* BEGIN GETTERS */

export function getHomeNodeId() {
    return homeNodeId;
}

/**
 *
 * @returns - The object of the sitemap.
 */
export function getAllSections(getByReference = false) {
    if (getByReference === true) {
        return sitemap.Sections;
    } else {
        return _.cloneDeep(sitemap.Sections);
    }
}

/**
 *
 * @returns - The object of the sitemap, sorted with optional sections sorted last.
 */
export function getAllSectionsWithMandatorySorted() {
    let sorted = _.cloneDeep(sitemap.Sections);

    sorted.sort(function (a, b) {
        var aMandatory = a.Mandatory === true;
        var bMandatory = b.Mandatory === true;

        // If both are mandatory or both are not mandatory, sort by section-number
        if (aMandatory === bMandatory) {
            var aNumber = a.ID;
            var bNumber = b.ID;
            return aNumber - bNumber;
        }

        // If a is mandatory and b is not, a comes first
        return aMandatory ? -1 : 1;
    });

    return sorted;
}

/**
 *
 * @param id - The section ID to find.
 * @param getByReference - This is false by default and should remain that way unless you REALLY know what you're doing.
 *
 * @returns - An object representing the section.
 */
export function getSection(id, getByReference = false) {
    if (getByReference === true) {
        return sitemap.Sections.find((section) => section.ID === parseInt(id));
    } else {
        return _.cloneDeep(sitemap.Sections).find((section) => section.ID === parseInt(id));
    }
}

/**
 * @param getByReference - This is false by default and should remain that way unless you REALLY know what you're doing.
 *
 * @returns - The first section with a key of IsCurrent set to true.
 */
export function getCurrentSection(getByReference = false) {
    if (getByReference === true) {
        return sitemap.Sections.find((section) => section.IsCurrent === true);
    } else {
        return _.cloneDeep(sitemap.Sections).find((section) => section.IsCurrent === true);
    }
}

export function getCurrentSectionTitle() {
    let title = sitemap.Sections.find((section) => section.IsCurrent === true).Title;
    if (inSelfDirected === true) {
        title = title + ' - ' + currentSelfDirectedTopic.Title;
    }
    return title;
}

/**
 *
 * @param slideId - A slide ID.
 * @returns - The section containing the given slide.
 */
export function getSectionContainingSlide(slideId) {
    for (var i = 0; i < sitemap.Sections.length; i++) {
        for (let j = 0; j < sitemap.Sections[i].Slides.length; j++) {
            let slide = sitemap.Sections[i].Slides[j];
            if (parseInt(slide.ID) === parseInt(slideId)) {
                return _.cloneDeep(sitemap.Sections[i]);
            } else if (slide.SlideType === 'slideSelfDirected') {
                for (let k = 0; k < slide.SelfDirectedTopics.length; k++) {
                    let section = slide.SelfDirectedTopics[k];
                    for (let l = 0; l < section.Slides.length; l++) {
                        let nestedSlide = section.Slides[l];
                        if (parseInt(nestedSlide.ID) === parseInt(slideId)) {
                            return _.cloneDeep(sitemap.Sections[i]);
                        }
                    }
                }
            }
        }
    }
}

/**
 * @param slideId - A slide ID.
 * @returns - The first slide found with an id of slideId.
 */
export function getSlide(slideId) {
    for (var i = 0; i < sitemap.Sections.length; i++) {
        for (let j = 0; j < sitemap.Sections[i].Slides.length; j++) {
            let slide = sitemap.Sections[i].Slides[j];
            if (parseInt(slide.ID) === parseInt(slideId)) {
                return _.cloneDeep(slide);
            } else if (slide.SlideType === 'slideSelfDirected') {
                for (let k = 0; k < slide.SelfDirectedTopics.length; k++) {
                    let section = slide.SelfDirectedTopics[k];
                    for (let l = 0; l < section.Slides.length; l++) {
                        let nestedSlide = section.Slides[l];
                        if (parseInt(nestedSlide.ID) === parseInt(slideId)) {
                            return _.cloneDeep(nestedSlide);
                        }
                    }
                }
            }
        }
    }
}

/**
 *
 * @returns - The first slide in the current section with a key of IsCurrent set to true.
 */
export function getCurrentSlide() {
    if (inSelfDirected === false) {
        return getCurrentSection().Slides.find((slide) => slide.IsCurrent === true);
    } else {
        return _.cloneDeep(currentSelfDirectedTopic).Slides.find((slide) => slide.IsCurrent === true);
    }
}

/**
 *
 * @returns - The index of the first slide in the current section with a key of IsCurrent set to true. Returns -1 if none found.
 */
export function getIndexOfCurrentSlide() {
    if (inSelfDirected === false) {
        let currentSection = getCurrentSection();
        if (currentSection) {
            return currentSection.Slides.findIndex((slide) => slide.IsCurrent === true);
        } else {
            return -1;
        }
    } else {
        return currentSelfDirectedTopic.Slides.findIndex((slide) => slide.IsCurrent === true);
    }
}

/**
 *
 * @returns - ID of last viewed slide (0 if there isn't a slide that was viewed last)
 */
export function getLastViewedSlideId() {
    return sitemap.LastViewedSlideId;
}

export function getCurrentSlideNum() {
    return getIndexOfCurrentSlide() + 1;
}

export function getTotalSlidesInCurrentSection() {
    if (inSelfDirected === false) {
        return getCurrentSection().Slides.length;
    } else {
        return currentSelfDirectedTopic.Slides.length;
    }
}

/**
 *
 * @param sectionId
 * @returns - The last slide in given section with a property of Completed that equals true, or the first slide if there isn't one or all * slides are completed.
 */
export function getLastCompletedSlideInSection(sectionId) {
    const slides = getSection(sectionId).Slides;

    for (let i = 0; i < slides.length; i++) {
        if (slides[i].Completed === false && i === 0) {
            return slides[i];
        } else if (slides[i].Completed === false) {
            return slides[i - 1];
        }
    }
    return slides[0];
}

export function isOrientationComplete() {
    const sections = getAllSections();

    let totalSectionsComplete = 0;
    for (let i = 0; i < sections.length; i++) {
        if (sections[i].Completed === true) totalSectionsComplete++;
    }

    if (sections.length === totalSectionsComplete) {
        return true;
    } else {
        return false;
    }
}

/**
 * Check if the orientation has been started by the user.
 * @returns true if any slides have been completed and false if not.
 */
export function isOrientationStarted() {
    const sections = getAllSections();

    for (let i = 0; i < sections.length; i++) {
        const slidesToCheck = sections[i].Slides;
        for (let k = 0; k < slidesToCheck.length; k++) {
            if (slidesToCheck[k].Completed === true) {
                return true;
            }
        }
    }
    return false;
}

/**
 *
 * @param slideIndex The index of the slide to be updated.
 * @param key - The property key to be added/updated.
 * @param value - The value to be added/updated.
 */
export function getSlideByIndex(slideIndex) {
    if (inSelfDirected === false) {
        return getCurrentSection().Slides[slideIndex];
    } else {
        return _.cloneDeep(currentSelfDirectedTopic.Slides[slideIndex]);
    }
}

/* BEGIN SETTERS */

/**
 *
 * @param slideIndex The index of the slide to be updated.
 * @param key - The property key to be added/updated.
 * @param value - The value to be added/updated.
 */
export function setSlidePropByIndex(slideIndex, key, value) {
    if (inSelfDirected === false) {
        getCurrentSection(true).Slides[slideIndex][key] = value;
    } else {
        currentSelfDirectedTopic.Slides[slideIndex][key] = value;
    }
    saveSitemapToLocalStorage();
}

export function setSlideProp(slideId, key, value) {
    for (let i = 0; i < sitemap.Sections.length; i++) {
        for (let j = 0; j < sitemap.Sections[i].Slides.length; j++) {
            let slide = sitemap.Sections[i].Slides[j];
            if (parseInt(slide.ID) === parseInt(slideId)) {
                slide[key] = value;
            } else if (slide.SlideType === 'slideSelfDirected') {
                setNestedSlideProp(slide, slideId, key, value);
            }
        }
    }
    saveSitemapToLocalStorage();
}

function setNestedSlideProp(slide, slideId, key, value) {
    for (let k = 0; k < slide.SelfDirectedTopics.length; k++) {
        let section = slide.SelfDirectedTopics[k];
        for (let l = 0; l < section.Slides.length; l++) {
            let nestedSlide = section.Slides[l];
            if (parseInt(nestedSlide.ID) === parseInt(slideId)) {
                nestedSlide[key] = value;
            } else if (nestedSlide.SlideType === 'slideSelfDirected') {
                setNestedSlideProp(nestedSlide, slideId, key, value);
            }
        }
    }
}

export function setSectionProp(sectionId, key, value) {
    for (var i = 0; i < sitemap.Sections.length; i++) {
        if (parseInt(sitemap.Sections[i].ID) === parseInt(sectionId)) {
            sitemap.Sections[i][key] = value;
        }
    }
    saveSitemapToLocalStorage();
}

/**
 *
 * @param value The node id to set for lastViewedSlideId.
 */
export function setLastViewedSlideId(value) {
    sitemap.LastViewedSlideId = value;
    saveSitemapToLocalStorage();
}

/* Self-Directed Topic Navigation */

export function enterSelfDirectedTopic(id = null, enterId: 'topic' | 'slide') {
    // A reference, not value.
    let topics = getCurrentSection(true).Slides.find((slide) => slide.IsCurrent === true).SelfDirectedTopics;

    if (enterId === 'topic') {
        currentSelfDirectedTopic = topics.find((topic) => topic.ID === parseInt(id));
        currentSelfDirectedTopic.Slides[0].IsCurrent = true;
    } else if (enterId === 'slide') {
        for (let i = 0; i < topics.length; i++) {
            let topicSlides = topics[i].Slides;
            for (let j = 0; j < topicSlides.length; j++) {
                if (parseInt(topicSlides[j].ID) === parseInt(id)) {
                    currentSelfDirectedTopic = topics.find((topic) => topic.ID === parseInt(topics[i].ID));
                    currentSelfDirectedTopic.Slides.find((slide) => slide.ID === parseInt(id)).IsCurrent = true;
                }
            }
        }
    }

    inSelfDirected = true;
}

export function isInSelfDirectedTopic() {
    return inSelfDirected;
}

export function leaveSelfDirectedTopic() {
    currentSelfDirectedTopic.Slides.forEach((slide) => {
        slide.IsCurrent = false;
    });

    inSelfDirected = false;
    currentSelfDirectedTopic = null;
}

/**
 *
 * @param slideId - A slide ID.
 * @returns - The self-directed slide containing the slide, or null if the slide isn't nested.
 */
export function getSelfDirectedSlideThatContainsSlide(slideId) {
    for (var i = 0; i < sitemap.Sections.length; i++) {
        for (let j = 0; j < sitemap.Sections[i].Slides.length; j++) {
            let slide = sitemap.Sections[i].Slides[j];
            if (slide.SlideType === 'slideSelfDirected') {
                for (let k = 0; k < slide.SelfDirectedTopics.length; k++) {
                    let topic = slide.SelfDirectedTopics[k];
                    for (let l = 0; l < topic.Slides.length; l++) {
                        let nestedSlide = topic.Slides[l];
                        if (parseInt(nestedSlide.ID) === parseInt(slideId)) {
                            return slide;
                        }
                    }
                }
            }
        }
    }

    return null;
}

export function lastViewedSlideIsHomeNode(): boolean {
    if (getLastViewedSlideId() === 0 || getLastViewedSlideId() === getHomeNodeId()) {
        return true;
    } else {
        return false;
    }
}

/**
 * Print sitemap to console.
 */
export function debug() {
    console.log(sitemap);
}

export function fakeAlphaSectionCompletion() {
    const firstSection = sitemap.Sections[0];
    setSectionProp(firstSection.ID, 'Completed', true);
    for (var i = 0; i < firstSection.Slides.length; i++) {
        setSlideProp(firstSection.Slides[i].ID, 'Completed', true);
    }
}
